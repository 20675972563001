import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Table } from 'antd';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Package as PackageResource } from 'constants/resources';
import './style.css';
import NormalHeader from 'components/PageHeader/NormalHeader';
var Packages = function (props) {
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var packageService = services.packageService;
    var data = packageService.usePackages(pageNumber, {}).data;
    var navigate = useNavigate();
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, "packageId-".concat(record.getId()));
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('name') }),
            key: 'name',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }, "packageName-".concat(record.getId()));
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('packageElement') }),
            key: 'packageElement',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getEntityName() }, "packageElement-".concat(record.getId()));
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('isEnabled') }),
            key: 'startDate',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getIsEnabled() ? _jsx(CheckOutlined, {}) : _jsx(CloseOutlined, {}) }, "packageStartDate-".concat(record.getId()));
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('action') }),
            fixed: 'right',
            render: function (text, record) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" });
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var goToPackageForm = function () { return navigate('/packages/new'); };
    var goToPackage = function (packageId) { return function () { return navigate("/packages/".concat(packageId)); }; };
    var menu = function (packageId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToPackage(packageId), children: props.translate('common:buttons.show') }, "1") }));
    };
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header.packagesList'), addButtonText: props.translate('common:buttons.newPackage'), addButtonFunction: goToPackageForm, resource: PackageResource }), _jsx("div", { className: "index-page-content", children: _jsx(Table, { columns: columns, pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, dataSource: data === null || data === void 0 ? void 0 : data.packages, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(Packages, 'packages');
