import { jsx as _jsx } from "react/jsx-runtime";
import EntityHeader from 'components/PageHeader/EntityHeader';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useNavigate, useParams } from 'react-router-dom';
import { Appointments } from 'constants/resources';
var Header = function (props) {
    var id = useParams().id;
    var navigate = useNavigate();
    var goToEditPage = function () { return navigate("/appointments/".concat(id, "/update")); };
    return (_jsx(EntityHeader, { title: props.translate('header.title'), editFn: props.appointment.getInEditableStatus() ? goToEditPage : undefined, resource: Appointments }));
};
export default localeTranslator(Header, 'showVisit');
