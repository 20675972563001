import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { Row, Col, Button } from 'antd';
import VisitPackageCard from 'components/cards/VisitPackageCard';
import localeTranslator from 'library/Hocs/LocalTranslator';
import '../../style.css';
import services from 'services';
import { ReservationPackage } from 'constants/resources';
var PackagesInfo = function (props) {
    var openPackageDrawer = function () { return props.setShowPackagesDrawer(true); };
    var permissionService = services.permissionService;
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { id: "packages-header", children: [_jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsx("div", { className: "Header-20-bold", children: props.translate('packagesInfo.title') }) }), _jsx(Col, { xs: 24, lg: 6, xl: 10 }), _jsx(Col, { xs: 24, lg: 8, xl: 4, children: permissionService.hasPermissionToAdd(ReservationPackage) &&
                            _jsxs(Button, { type: "link", className: "Header-R-14", onClick: openPackageDrawer, children: [props.translate('packagesInfo.addPackages'), " +"] }) })] }), _jsx("div", { id: "profile-content", children: _jsx(Row, { children: props.appointment.getAppointmentDetails().map(function (detail) {
                        return (_jsxs(_Fragment, { children: [_jsx(Col, { xs: 24, lg: 7, children: _jsx(VisitPackageCard, { reservationPackage: detail.getReservationPackage(), onDeselectPackage: function () { }, onSelectPackage: function () { }, backgroundClass: "normal-card-color", isSelected: false, onSettlementPress: undefined, onAddUsagePress: undefined, onUnassignPress: undefined }) }), _jsx(Col, { xs: 24, lg: 1 })] }));
                    }) }) })] }));
};
export default localeTranslator(PackagesInfo, 'showVisit');
