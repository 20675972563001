import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormSelect } from 'components/AntEnhancement/FormControls';
import services from 'services';
import './style.css';
var InternalMoveForm = function (props) {
    var clinicService = services.clinicService, userService = services.userService;
    var clinics = clinicService.useClinics().data;
    var loggedInData = userService.useLoginData().data;
    var onChangeEntityId = function (entityId) { return entityId && props.stockMovement.setEntityId(Number(entityId)); };
    var getClinicOptions = function () {
        if (!loggedInData) {
            return [];
        }
        var filteredClinics = clinics === null || clinics === void 0 ? void 0 : clinics.filter(function (clinic) { return clinic.getCode() != loggedInData.clinicCode; });
        return (filteredClinics === null || filteredClinics === void 0 ? void 0 : filteredClinics.map(function (clinic) { return ({
            value: clinic.getId(),
            title: clinic.getName()
        }); })) || [];
    };
    return (_jsx(_Fragment, { children: _jsx(FormSelect, { label: props.translate('clinic'), name: "clinic", rules: [
                { required: true, message: props.translate('errors.clinic') }
            ], placeHolder: props.translate('clinic'), onSelect: onChangeEntityId, options: getClinicOptions(), initialValue: props.stockMovement.getEntityId() || null }) }));
};
export default localeTranslator(InternalMoveForm, 'stockMovementForm');
