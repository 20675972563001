import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Drawer, Form, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { DocumentLine, DocumentLineGroup } from 'models';
import { useEffect, useState } from 'react';
import services from 'services';
import 'pages/ShowAppointment/style.css';
import DocumentLineGroupForm from 'components/forms/accounting/documents/DocumentLineGroupForm';
var DocumentLineGroupDrawer = function (props) {
    var documentLineGroup = useState(new DocumentLineGroup())[0];
    var documentLines = useState([new DocumentLine(), new DocumentLine()])[0];
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var documentLineGroupService = services.documentLineGroupService, accountService = services.accountService, documentLineService = services.documentLineService;
    var accounts = accountService.useMiniAccounts().data;
    var documentLinesMetaInfo = documentLineService.useMetaInfo().data;
    var documentLineGroupsMetaInfo = documentLineGroupService.useMetaInfo().data;
    var _b = documentLineGroupService.useCreateDocumentLineGroup(), createDocumentLineGroup = _b.mutate, errorResponse = _b.isError;
    var onSubmit = function () {
        setDisabled(true);
        documentLineGroup.setDocumentId(props.document.getId());
        documentLineGroup.setDocumentLines(documentLines);
        createDocumentLineGroup({ documentLineGroup: documentLineGroup, successMessage: props.translate('messages.documentLineGroupCreated') });
    };
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    return (_jsx(Drawer, { title: props.translate('drawers.addNewDocumentLineGroup.title'), placement: "bottom", open: props.visiable, size: "large", onClose: onClose, className: "transaction-drawer", children: _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: [accounts && documentLinesMetaInfo && documentLineGroupsMetaInfo &&
                    _jsx(DocumentLineGroupForm, { accounts: accounts, documentLineGroup: documentLineGroup, document: props.document, entityTypes: documentLinesMetaInfo.getEntityTypes(), transactionEntryTypes: documentLinesMetaInfo.getTransactionEntryTypes(), paidOnEntities: documentLineGroupsMetaInfo.getPaidOnEntities(), paidFromEntities: documentLineGroupsMetaInfo.getPaidFromEntities(), elementIdentifer: 'add-document-line', documentLines: documentLines }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) })] }) }, "bottom"));
};
export default localeTranslator(DocumentLineGroupDrawer, 'showDocument');
