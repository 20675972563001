import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, Dropdown, Table } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { EllipsisOutlined } from '@ant-design/icons';
import EmptyComponent from 'components/EmptyComponent';
import '../../style.css';
import { useNavigate } from 'react-router-dom';
import './style.css';
var AppointmentDetails = function (props) {
    var navigate = useNavigate();
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.appointments.info.id') }),
            key: 'package_price_id',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getId() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.appointments.info.quantity') }),
            key: 'package_price_quantity',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getQuantity() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.appointments.info.notes') }),
            key: 'package_price_quantity',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getNote() }); }
        },
        {
            key: 'action',
            render: function (text, record, index) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getAppointmentId()), className: "three-dot" }, "action-".concat(index));
            }
        }
    ];
    var menu = function (appointmentId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToAppointment(appointmentId), children: props.translate('common:buttons.show') }, "1") }));
    };
    var goToAppointment = function (appointmentId) { return function () { return navigate("/appointments/".concat(appointmentId)); }; };
    return (_jsxs("div", { className: "page-subcontent", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('tables.appointments.header.appointments') }), props.currentReservationPackage &&
                _jsx(Table, { columns: columns, pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: props.currentReservationPackage.getAppointmentDetails().length,
                        showSizeChanger: false,
                        responsive: true
                    }, dataSource: props.currentReservationPackage.getAppointmentDetails(), locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } })] }));
};
export default localeTranslator(AppointmentDetails, 'showReservationPackage');
