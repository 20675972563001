import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from 'antd';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import NormalHeader from 'components/PageHeader/NormalHeader';
var AccountTypes = function (props) {
    var accountTypeService = services.accountTypeService;
    var accountTypes = accountTypeService.useAccountTypes().data;
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('name') }),
            key: 'name',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }, 'name-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('nature') }),
            key: 'nature',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getNature() }, 'nature-' + index);
            }
        }
    ];
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header.accountTypes') }), _jsx("div", { className: "index-page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: false, dataSource: accountTypes, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(AccountTypes, 'accountTypes');
