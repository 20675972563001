import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Product as ProductResource } from 'constants/resources';
import './style.css';
import NormalHeader from 'components/PageHeader/NormalHeader';
var Products = function (props) {
    var navigate = useNavigate();
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var productService = services.productService;
    var data = productService.useProducts(pageNumber).data;
    var goToProduct = function (productId) { return function () { return navigate("/products/".concat(productId)); }; };
    var menu = function (productId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToProduct(productId), children: props.translate('common:buttons.show') }, "1") }));
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('name') }),
            key: 'productName',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }, 'productName-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('barcode') }),
            key: 'barcode',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getBarcode() }, 'barcode-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('systemCode') }),
            key: 'systemCode',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getSystemCode() }, 'systemCode-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('quantity') }),
            key: 'quantity',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getQuantity() }, 'quantity-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('category') }),
            key: 'category',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getProductCategory().getName() }, 'category-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('action') }),
            fixed: 'right',
            render: function (text, record) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" });
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var goToNewProductForm = function () { return navigate('/products/new'); };
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header'), addButtonText: props.translate('common:buttons.newProduct'), addButtonFunction: goToNewProductForm, resource: ProductResource }), _jsx("div", { className: "index-page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, 
                    // scroll={{ x: 1300 }}
                    dataSource: data === null || data === void 0 ? void 0 : data.products, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(Products, 'products');
