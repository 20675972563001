import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import services from 'services';
import SearchHeader from 'components/PageHeader/SearchHeader';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var Appointments = function (props) {
    var _a;
    var navigate = useNavigate();
    var _b = useState(1), pageNumber = _b[0], setPageNumber = _b[1];
    var _c = useState('patient_phone_number'), searchKey = _c[0], setSearchKey = _c[1];
    var _d = useState(''), searchValue = _d[0], setSearchValue = _d[1];
    var appointmentService = services.appointmentService;
    var data = appointmentService.useAppointments(pageNumber, (_a = {}, _a[searchKey] = searchValue, _a)).data;
    var goToAppointment = function (appointmentId) { return function () { return navigate("/appointments/".concat(appointmentId)); }; };
    var onSearchKeyPress = function (key) { return function () { return setSearchKey(key); }; };
    var onSearchValueChange = function (value) {
        setPageNumber(1);
        setSearchValue(value);
    };
    var menu = function (appointmentId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToAppointment(appointmentId), children: props.translate('common:buttons.show') }, "1") }));
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientName') }),
            key: 'patientName',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getPatient().getName() }, 'patientName-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientMobile') }),
            width: '14%',
            key: 'patientMobile',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getPatient().getPrimaryPhone() }, 'patientMobile-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('doctorName') }),
            width: '14%',
            key: 'doctorName',
            render: function (text, record, index) {
                var _a;
                return _jsx("div", { className: "body---14pt-R table-body-content", children: (_a = record.getDoctor().getUser()) === null || _a === void 0 ? void 0 : _a.getName() }, 'doctorName-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('status') }),
            width: '14%',
            key: 'status',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getDisplayedStatus() }, 'status-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('clinic') }),
            key: 'clinic',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getClinic().getName() }, 'clinic-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('time') }),
            key: 'time',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getFormattedAppointmentTime() }, 'time-' + index);
            }
        },
        {
            key: 'action',
            render: function (text, record, index) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" }, "action-".concat(index));
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var searchMenu = function () {
        return (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: onSearchKeyPress('patient_phone_number'), children: props.translate('patientPhone') }, "1"), _jsx(Menu.Item, { onClick: onSearchKeyPress('clinic_name'), children: props.translate('clinicName') }, "2")] }));
    };
    var getSearchInputPlaceHolder = function () {
        if (searchKey === 'patient_phone_number') {
            return props.translate('header.searchByPatientPhone');
        }
        else if (searchKey === 'clinic_name') {
            return props.translate('header.searchByClinicName');
        }
        else {
            return undefined;
        }
    };
    return (_jsxs("div", { children: [_jsx(SearchHeader, { title: props.translate('header.appointmentsList'), menu: searchMenu(), onSearchValueChange: onSearchValueChange, searchInputPlaceHolder: getSearchInputPlaceHolder() }), _jsx("div", { className: "page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, 
                    // scroll={{ x: 1300 }}
                    dataSource: data === null || data === void 0 ? void 0 : data.appointments, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(Appointments, 'appointments');
