var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { instanceToPlain, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { PaginationMetaData, Supplier, SupplierType } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from '../ApiService';
import { useNavigate } from 'react-router';
var SupplierService = /** @class */ (function (_super) {
    __extends(SupplierService, _super);
    function SupplierService() {
        var _this = _super.call(this) || this;
        _this.suppliersRoot = 'suppliers';
        _this.successMessage = '';
        return _this;
    }
    SupplierService.prototype.useSuppliers = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.inventory.suppliers, pageNumber, filters], function () {
            return _this.get(_this.suppliersRoot, __assign(__assign({}, filters), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                var suppliers = plainToInstance(Supplier, data === null || data === void 0 ? void 0 : data.suppliers);
                var meta = plainToInstance(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta);
                return { suppliers: suppliers, meta: meta };
            }
        });
    };
    SupplierService.prototype.useSupplierListing = function () {
        var _this = this;
        return useQuery(queries.inventory.suppliers, function () {
            return _this.get("".concat(_this.suppliersRoot, "/mini_index"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(Supplier, data);
            }
        });
    };
    SupplierService.prototype.useSupplier = function (id) {
        var _this = this;
        return useQuery(['show', queries.inventory.suppliers, id], function () {
            return _this.get("".concat(_this.suppliersRoot, "/").concat(id));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(Supplier, data);
            }
        });
    };
    SupplierService.prototype.useCreateSupplier = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var supplier = _a.supplier, successMessage = _a.successMessage;
            _this.successMessage = successMessage;
            var data = { supplier: instanceToPlain(supplier) };
            return _this.post(_this.suppliersRoot, { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(queries.inventory.suppliers);
                _this.dipatachCreationSuccess({ successMessage: _this.successMessage });
                navigate('/suppliers');
            }
        });
    };
    SupplierService.prototype.useSupplierTypes = function () {
        var _this = this;
        return useQuery([queries.inventory.suppliers, '-types'], function () {
            return _this.get("".concat(_this.suppliersRoot, "/types"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(SupplierType, data);
            }
        });
    };
    SupplierService.getInstance = function () {
        if (!SupplierService.supplierService) {
            SupplierService.supplierService = new SupplierService();
        }
        return SupplierService.supplierService;
    };
    return SupplierService;
}(ApiService));
export default SupplierService;
