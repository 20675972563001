import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Space, Form } from 'antd';
import { MedicalMachine } from 'models';
import services from 'services';
import MedicalMachineForm from 'components/forms/medicalMachines/MedicalMachineForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
var NewMedicalMachine = function (props) {
    var medicalMachine = useState(new MedicalMachine())[0];
    var medicalMachineService = services.medicalMachineService, unitMeasurementService = services.unitMeasurementService, clinicService = services.clinicService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = medicalMachineService.useCreateMedicalMachine(), createMedicalMachine = _b.mutate, errorResponse = _b.isError;
    var unitMeasurements = unitMeasurementService.useUnitMeasurements().data;
    var clinics = clinicService.useClinics().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        createMedicalMachine({ medicalMachine: medicalMachine, successMessage: props.translate('machineCreated') });
    };
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    var scrollToFirstElement = function () { return form.scrollToField('name', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: _jsxs(Space, { direction: 'vertical', size: 60, style: { width: '100%' }, children: [unitMeasurements && clinics &&
                            _jsx(MedicalMachineForm, { medicalMachine: medicalMachine, unitMeasurements: unitMeasurements, clinics: clinics }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] }) })] }));
};
export default localeTranslator(NewMedicalMachine, 'newMedicalMachine');
