var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { classToPlain, plainToClass, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { Album, AppointmentFilters, Diagnose, FollowUp, MedicalMachineVisitDetail, MedicalTest, PaginationMetaData, Patient, ReservationPackage, ReservationPackageFilters, ReservationTransaction } from 'models';
import Prescription from 'models/entities/Prescription';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import ApiService from './ApiService';
import ReservationService from './ReservationService';
import ReservationPackageService from './ReservationPackageService';
import AppointmentService from './AppointmentService';
var PatientService = /** @class */ (function (_super) {
    __extends(PatientService, _super);
    function PatientService() {
        var _this = _super.call(this) || this;
        _this.patientsRoot = 'patients';
        _this.successCreationMessage = '';
        _this.patientId = 0;
        _this.reservationService = ReservationService.getInstance();
        _this.reservationPackageService = ReservationPackageService.getInstance();
        _this.appointmentService = AppointmentService.getInstance();
        return _this;
    }
    PatientService.prototype.useCreatePatient = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var patient = _a.patient, successMessage = _a.successMessage;
            _this.successCreationMessage = successMessage;
            var currentPlainPatient = classToPlain(patient);
            var data = { patient: currentPlainPatient };
            return _this.post(_this.patientsRoot, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries(queries.patient);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                navigate('/patients');
            }
        });
    };
    PatientService.prototype.useUpdatePatient = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var patient = _a.patient, successMessage = _a.successMessage;
            _this.successCreationMessage = successMessage;
            var currentPlainPatient = classToPlain(patient);
            // currentPlainPatient.patient_details = currentPlainPatient.patient_details[0];
            var data = { patient: currentPlainPatient };
            return _this.put("".concat(_this.patientsRoot, "/").concat(patient.getId()), { data: data });
        }, {
            onSuccess: function (response) {
                var data = response.data;
                var patient = plainToClass(Patient, data);
                queryClient.invalidateQueries([queries.patient, patient.getId()]);
                queryClient.invalidateQueries(queries.patient);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                navigate('/patients/' + patient.getId());
            }
        });
    };
    PatientService.prototype.usePatients = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.patients, pageNumber, filters], function () {
            return _this.get(_this.patientsRoot, __assign(__assign({}, filters), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            select: function (response) {
                var data = response.data;
                var patients = plainToClass(Patient, data === null || data === void 0 ? void 0 : data.patients);
                var meta = plainToClass(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta);
                return { patients: patients, meta: meta };
            }
        });
    };
    PatientService.prototype.usePatientSearchByPrimaryPhone = function (primaryPhone) {
        var _this = this;
        var uri = this.patientsRoot + '/search-list';
        return useQuery([queries.patients, primaryPhone], function () {
            return _this.get(uri, { primary_phone: primaryPhone });
        }, {
            keepPreviousData: false,
            enabled: primaryPhone.length >= 7,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(Patient, data);
            }
        });
    };
    PatientService.prototype.useGetPatient = function (id) {
        var _this = this;
        return useQuery([queries.patient, id], function () {
            return _this.get("".concat(_this.patientsRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(Patient, data);
            }
        });
    };
    PatientService.prototype.useGetPatientReservationPackages = function (patientId, available) {
        if (available === void 0) { available = false; }
        var reservationPackageFilters = new ReservationPackageFilters();
        reservationPackageFilters.setPatientId(patientId);
        available && reservationPackageFilters.setAvailable(true);
        return this.reservationPackageService.useAllReservationPackages(reservationPackageFilters);
    };
    PatientService.prototype.useGetPatientAppointments = function (patientId) {
        var appointmentFilters = new AppointmentFilters();
        appointmentFilters.setPatientId(patientId);
        return this.appointmentService.useAllAppointments(appointmentFilters);
    };
    PatientService.prototype.useGetPatientPrescriptions = function (patientId) {
        var _this = this;
        return useQuery([queries.patient, patientId, 'prescriptions'], function () {
            return _this.get("".concat(_this.patientsRoot, "/").concat(patientId, "/prescriptions"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(Prescription, data);
            }
        });
    };
    PatientService.prototype.useGetPatientMedicalTests = function (patientId) {
        var _this = this;
        return useQuery([queries.patient, patientId, 'medicalTests'], function () {
            return _this.get("".concat(_this.patientsRoot, "/").concat(patientId, "/medical_analyses"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(MedicalTest, data);
            }
        });
    };
    PatientService.prototype.useGetPatientFollowUps = function (patientId) {
        var _this = this;
        return useQuery([queries.patient, patientId, 'followUps'], function () {
            return _this.get("".concat(_this.patientsRoot, "/").concat(patientId, "/follow_ups"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(FollowUp, data);
            }
        });
    };
    PatientService.prototype.useGetPatientDiagnoses = function (patientId) {
        var _this = this;
        return useQuery([queries.patient, patientId, 'diagnoses'], function () {
            return _this.get("".concat(_this.patientsRoot, "/").concat(patientId, "/diagnoses"));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(Diagnose, data);
            }
        });
    };
    PatientService.prototype.useGetPatientMedicalMachineUsages = function (patientId) {
        var _this = this;
        return useQuery([queries.patient, patientId, 'medicalMachineUsages'], function () {
            return _this.get("".concat(_this.patientsRoot, "/").concat(patientId, "/medical_machine_usages"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(MedicalMachineVisitDetail, data);
            }
        });
    };
    PatientService.prototype.useGetPatientVisitImages = function (patientId) {
        var _this = this;
        return useQuery([queries.patient, patientId, 'images'], function () {
            return _this.get("".concat(_this.patientsRoot, "/").concat(patientId, "/visit/images"));
        }, {
            select: function (_a) {
                var data = _a.data;
                var albums = plainToClass(Album, data);
                var images = albums.flatMap(function (album) { return album.getImages(); });
                return images.sort(function (a, b) { return b.getId() - a.getId(); });
            }
        });
    };
    PatientService.prototype.useGetPatientAvailableReservationPackages = function (patientId) {
        var _this = this;
        return useQuery([queries.patient, patientId, 'availableReservationPackages'], function () {
            return _this.get("".concat(_this.patientsRoot, "/").concat(patientId, "/reservations/packages"));
        }, {
            enabled: !!patientId,
            select: function (_a) {
                var data = _a.data;
                var reservationPackages = plainToClass(ReservationPackage, data.reservations_packages);
                var reservationTransactions = plainToClass(ReservationTransaction, data.meta.reservation_transactions);
                var totalPrices = _this.reservationService.getReservationPackagesPrices(reservationPackages, reservationTransactions);
                return { reservationPackages: reservationPackages, totalPrices: totalPrices };
            }
        });
    };
    PatientService.prototype.useCreatePatientDetails = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var patientDetails = _a.patientDetails, successMessage = _a.successMessage, patientId = _a.patientId;
            _this.patientId = patientId;
            _this.successCreationMessage = successMessage;
            var data = { patient_details: classToPlain(patientDetails) };
            var url = _this.patientsRoot + '/' + patientId + '/add/details';
            return _this.post(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.patient, _this.patientId, 'details']);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    PatientService.getInstance = function () {
        if (!PatientService.PatientInstance) {
            PatientService.PatientInstance = new PatientService();
        }
        return PatientService.PatientInstance;
    };
    return PatientService;
}(ApiService));
export default PatientService;
