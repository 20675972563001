import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from 'antd';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import { useNavigate } from 'react-router';
import { ProductCategory as ProductCategoryResource } from 'constants/resources';
import NormalHeader from 'components/PageHeader/NormalHeader';
var ProductCategories = function (props) {
    var navigate = useNavigate();
    var productCategoryService = services.productCategoryService;
    var productCategories = productCategoryService.useProductCategories().data;
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('name') }),
            key: 'name',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }, 'name-' + index);
            }
        }
    ];
    var goToNewProductCategoryForm = function () { return navigate('/product_categories/new'); };
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header'), addButtonText: props.translate('common:buttons.newProductCategory'), addButtonFunction: goToNewProductCategoryForm, resource: ProductCategoryResource }), _jsx("div", { className: "index-page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: false, dataSource: productCategories, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(ProductCategories, 'productCategories');
