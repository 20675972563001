import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import { StockMovement } from 'models';
import localeTranslator from 'library/Hocs/LocalTranslator';
import StockMovementForm from 'components/forms/inventory/stockMovements/StockMovementForm';
var NewStockMovement = function (props) {
    var stockMovement = useState(new StockMovement())[0];
    var productService = services.productService, stockMovementService = services.stockMovementService;
    var products = productService.useMiniProducts().data;
    var metaInfo = stockMovementService.useMetaInfo().data;
    var _a = stockMovementService.useCreateStockMovement(), createStockMovement = _a.mutate, errorResponse = _a.isError;
    var _b = useState(false), disabled = _b[0], setDisabled = _b[1];
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        createStockMovement({ stockMovement: stockMovement, successMessage: 'StockMovementCreated' });
    };
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    var scrollToFirstElement = function () { return form.scrollToField('name', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: [products && metaInfo &&
                        _jsx(StockMovementForm, { stockMovement: stockMovement, metaInfo: metaInfo, products: products }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] })] }));
};
export default localeTranslator(NewStockMovement, 'stockMovementForm');
