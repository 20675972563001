import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router';
import { Col, Row } from 'antd';
import services from 'services';
import UserCard from 'components/cards/UserCard';
import EmptyComponent from 'components/EmptyComponent';
import './style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Doctor } from 'constants/resources';
import NormalHeader from 'components/PageHeader/NormalHeader';
import Loader from 'components/AntEnhancement/Loader';
var Doctors = function (props) {
    var doctorService = services.doctorService, specializationService = services.specializationService, permissionService = services.permissionService;
    var doctors = doctorService.useDoctors({}).data;
    var _a = specializationService.useSpecializations(), specializations = _a.data, isLoading = _a.isLoading;
    var navigate = useNavigate();
    var goToNewDoctorForm = function () { return navigate('/doctors/new'); };
    var goToDoctorProfile = function (id) {
        if (permissionService.hasPermissionToShow(Doctor)) {
            navigate("/doctors/".concat(id));
        }
    };
    var doctorsCards = function () {
        return doctors === null || doctors === void 0 ? void 0 : doctors.map(function (doctor) {
            var _a, _b, _c;
            return doctor.getUser() && (_jsx(Col, { onClick: function () { return goToDoctorProfile(doctor.getId()); }, children: _jsx(UserCard, { name: props.translate('drPrefix') + ' ' + doctor.getUser().getName(), subTitle: ((_a = specializationService.getSpecializationDataByID(specializations, doctor.getSpecializationId())) === null || _a === void 0 ? void 0 : _a.getName()) || '', phoneNumber: doctor.getUser().getMobile(), email: doctor.getUser().getEmail(), id: doctor.getId().toString(), imagePath: (_c = (_b = doctor.getAlbum()) === null || _b === void 0 ? void 0 : _b.getImage()) === null || _c === void 0 ? void 0 : _c.getImagePath() }, "doctor-".concat(doctor.getId())) }, "doctor-col-".concat(doctor.getId())));
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(NormalHeader, { title: props.translate('header.doctorsList'), addButtonText: props.translate('common:buttons.newDoctor'), addButtonFunction: goToNewDoctorForm, resource: Doctor }), _jsx(Loader, { renderSpinner: isLoading, children: _jsx("div", { className: "index-page-content", children: _jsx(Row, { children: doctors ?
                            doctorsCards() :
                            _jsx("div", { className: "empty-component-container", children: _jsx(EmptyComponent, { description: props.translate('emptyText') }) }) }) }) })] }));
};
export default localeTranslator(Doctors, 'doctors');
