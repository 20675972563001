import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
// import { useNavigate } from 'react-router';
import services from 'services';
import { AuthRoutes, LoggedInRoutes } from 'routes';
import { i18nSetup } from 'library/utilities/Internationalization';
import AppLayout from 'components/Layout';
import languages from 'constants/languages';
import ToasterMark from 'library/components/ToasterMark';
import { RightMarkIcon, WrongtMarkIcon } from 'assets/icons';
import { ERROR_API_EVENT, SUCCESS_API_EVENT } from 'constants/events';
import jwt_decode from 'jwt-decode';
import { get } from 'library/utilities/Storage';
export default function App() {
    var userService = services.userService, toastrService = services.toastrService;
    var data = userService.useLoginData().data;
    var userInfo = userService.useCurrentUser().data;
    userService.useUserPermissions(userInfo);
    var isLoggedIn = function () { return !!(data === null || data === void 0 ? void 0 : data.token); };
    useEffect(function () {
        i18nSetup(data === null || data === void 0 ? void 0 : data.lang);
    }, [data]);
    var token = get('token');
    var tokenData = token ? jwt_decode(token) : {};
    var logout = userService.useLogout().mutate;
    useEffect(function () {
        var expiration = Number(tokenData.exp) * 1000;
        if (Date.now() > expiration) {
            logout({});
        }
    }, [tokenData]);
    useEffect(function () {
        // eslint-disable-next-line
        document.addEventListener(ERROR_API_EVENT, function (event) {
            toastrService.displayErrorToastr(event.detail, _jsx(ToasterMark, { ToasterIcon: WrongtMarkIcon }));
        });
        document.addEventListener(SUCCESS_API_EVENT, function (event) {
            toastrService.displaySuccessToastr(event.detail.successMessage, _jsx(ToasterMark, { ToasterIcon: RightMarkIcon }));
        });
    }, []);
    var getDirection = function () { return (data === null || data === void 0 ? void 0 : data.lang) === languages.AR ? 'rtl' : 'ltr'; };
    var getUserInfo = function () { return userInfo && _jsx(LoggedInRoutes, {}); };
    return (_jsx(ConfigProvider, { direction: getDirection(), children: _jsxs(BrowserRouter, { children: [!isLoggedIn() && _jsx(AuthRoutes, {}), isLoggedIn() &&
                    _jsx(AppLayout, { appContent: getUserInfo() })] }) }));
}
