var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { instanceToPlain, plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { Doctor } from 'models';
import { useMutation, useQueryClient } from 'react-query';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import ApiService from './ApiService';
var DoctorService = /** @class */ (function (_super) {
    __extends(DoctorService, _super);
    function DoctorService() {
        var _this = _super.call(this) || this;
        _this.doctorsRoot = 'doctors';
        _this.successCreationMessage = '';
        return _this;
    }
    DoctorService.prototype.useDoctors = function (filters) {
        var _this = this;
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.doctors, filters], function () {
            return _this.get(_this.doctorsRoot, __assign({}, filters));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(Doctor, data);
            }
        });
    };
    DoctorService.prototype.useGetDoctor = function (id) {
        var _this = this;
        return useQuery([queries.doctor, id], function () {
            return _this.get("".concat(_this.doctorsRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(Doctor, data);
            }
        });
    };
    DoctorService.prototype.useCreateDoctor = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var doctor = _a.doctor, successMessage = _a.successMessage;
            _this.successCreationMessage = successMessage;
            var data = { doctor: instanceToPlain(doctor) };
            return _this.post(_this.doctorsRoot, { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(queries.doctors);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                navigate('/doctors');
            }
        });
    };
    DoctorService.getInstance = function () {
        if (!DoctorService.doctorInstance) {
            DoctorService.doctorInstance = new DoctorService();
        }
        return DoctorService.doctorInstance;
    };
    return DoctorService;
}(ApiService));
export default DoctorService;
