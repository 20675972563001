import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ar';
import 'dayjs/locale/en';
import i18n from 'i18next';
dayjs.locale(i18n.language);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
export var getDiffFromNow = function (date) {
    var formattedDate = dayjs(date.toString()).format('YYYY-MM-DD');
    return dayjs(formattedDate).locale(i18n.language).fromNow(true);
};
export var formatDate = function (date, formatter) {
    return dayjs(date).locale(i18n.language).format(formatter);
};
