import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Form, Input } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormDatePicker, FormInputNumber, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
import InternalMovementForm from './InternalMovementForm';
import PurchaseMovementForm from './PurchaseMovementForm';
import useForceUpdate from 'library/hooks/forceUpdate';
var StockMovementForm = function (props) {
    var _a;
    var forceUpdate = useForceUpdate();
    var onChangeProductId = function (productId) { return productId && props.stockMovement.setProductId(Number(productId)); };
    var onChangeStockMovementType = function (type) {
        type && props.stockMovement.setMoveType(type.toString());
        forceUpdate();
    };
    var onChangeQuantity = function (quantity) { return quantity && props.stockMovement.setQuantity(quantity); };
    var onChangePrice = function (price) { return price && props.stockMovement.setPrice(price); };
    var onChangePriceType = function (value) { return props.stockMovement.setPriceType(value.toString()); };
    var onChangeStartedAt = function (startedAt) {
        startedAt && props.stockMovement.setStartedAt(startedAt.toDate());
    };
    var getProductOptions = function () {
        return props.products.map(function (product) { return ({
            value: product.getId(),
            title: product.getName()
        }); });
    };
    var getStockMovementTypeOptions = function () {
        return props.metaInfo.getStockMovementTypes().map(function (product) { return ({
            value: product.getValue(),
            title: product.getName()
        }); });
    };
    var getPriceTypeOptions = function () {
        return props.metaInfo.getPriceTypes().map(function (product) { return ({
            value: product.getValue(),
            title: product.getName()
        }); });
    };
    var stockMovementEntityComponent = function () {
        var Component = null;
        switch (props.stockMovement.getMoveType()) {
            case 'Inventory::Movements::InternalMovement': {
                Component = _jsx(InternalMovementForm, { stockMovement: props.stockMovement });
                break;
            }
            case 'Inventory::Movements::PurchaseMovement': {
                Component = _jsx(PurchaseMovementForm, { stockMovement: props.stockMovement });
                break;
            }
            default: {
                Component = _jsx(_Fragment, {});
                break;
            }
        }
        return Component;
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "form-part", children: [_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormSelect, { label: props.translate('movementType'), name: "movementType", rules: [
                                    { required: true, message: props.translate('errors.movementType') }
                                ], placeHolder: props.translate('movementType'), onSelect: onChangeStockMovementType, options: getStockMovementTypeOptions(), initialValue: props.stockMovement.getType() || null }) }), _jsx(Col, { xs: 24, sm: 24, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormSelect, { label: props.translate('product'), name: "product", rules: [
                                    { required: true, message: props.translate('errors.product') }
                                ], placeHolder: props.translate('product'), onSelect: onChangeProductId, options: getProductOptions(), initialValue: ((_a = props.stockMovement.getStock()) === null || _a === void 0 ? void 0 : _a.getProductId()) || null }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(Form.Item, { label: props.translate('price'), required: true, children: _jsxs(Input.Group, { compact: true, children: [_jsx(FormInputNumber, { formClasses: "form-item body---14pt-R input-group-size group-input", inputClasses: 'group-input-border number-input', name: "price", rules: [
                                                { required: true, message: props.translate('errors.price') }
                                            ], placeHolder: props.translate('price'), onChange: onChangePrice, min: 0 }), _jsx(FormSelect, { formClasses: "form-item body---14pt-R select-group-size group-input", selectClasses: "form-item select-border", name: "priceType", rules: [
                                                { required: true, message: props.translate('errors.priceType') }
                                            ], placeHolder: props.translate('priceType'), onSelect: onChangePriceType, options: getPriceTypeOptions() })] }) }) }), _jsx(Col, { xs: 24, sm: 24, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormInputNumber, { label: props.translate('quantity'), name: 'quantity', min: 0, rules: [
                                    { required: true, message: props.translate('errors.quantity') }
                                ], placeHolder: props.translate('quantity'), onChange: onChangeQuantity }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormDatePicker, { label: props.translate('startedAt'), name: 'startedAt', rules: [
                                    { required: true, message: props.translate('errors.startedAt') }
                                ], placeHolder: props.translate('startedAt'), onChange: onChangeStartedAt }) }), _jsx(Col, { xs: 24, sm: 24, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: stockMovementEntityComponent() })] }), _jsx(Row, {})] }) }));
};
export default localeTranslator(StockMovementForm, 'stockMovementForm');
