var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import PatientDetails from './PatientDetails';
import { PatientArea, Reservation } from 'models';
var Patient = /** @class */ (function (_super) {
    __extends(Patient, _super);
    function Patient() {
        return _super.call(this) || this;
    }
    Patient.prototype.getId = function () {
        return Number(this.id);
    };
    Patient.prototype.getName = function () {
        return this.adjustName(this.name || '');
    };
    Patient.prototype.setName = function (name) {
        this.name = name;
    };
    Patient.prototype.setFirstName = function (first_name) {
        this.first_name = first_name;
    };
    Patient.prototype.getFirstName = function () {
        return this.first_name;
    };
    Patient.prototype.setMiddleName = function (middle_name) {
        this.middle_name = middle_name;
    };
    Patient.prototype.getMiddleName = function () {
        return this.middle_name;
    };
    Patient.prototype.setLastName = function (last_name) {
        this.last_name = last_name;
    };
    Patient.prototype.getLastName = function () {
        return this.last_name;
    };
    Patient.prototype.getSecondName = function () {
        var _a;
        return (_a = this.getName()) === null || _a === void 0 ? void 0 : _a.split(' ')[1];
    };
    Patient.prototype.getThirdName = function () {
        var _a;
        return (_a = this.getName()) === null || _a === void 0 ? void 0 : _a.split(' ')[2];
    };
    Patient.prototype.getSystemCode = function () {
        return this.system_code;
    };
    Patient.prototype.getCode = function () {
        return this.code;
    };
    Patient.prototype.setCode = function (code) {
        if (code === void 0) { code = ''; }
        this.code = code;
    };
    Patient.prototype.getGender = function () {
        return this.gender;
    };
    Patient.prototype.setGender = function (gender) {
        this.gender = gender;
    };
    Patient.prototype.getSecondaryPhone = function () {
        var _a, _b;
        var telephones = (_a = this.getPatientDetails()) === null || _a === void 0 ? void 0 : _a.getTelephones();
        return telephones && telephones[0] ? (_b = telephones[0]) === null || _b === void 0 ? void 0 : _b['number'] : '-';
    };
    Patient.prototype.setSecondaryPhone = function (telephone) {
        if (telephone === void 0) { telephone = ''; }
        this.getPatientDetails().setTelephones(telephone);
    };
    Patient.prototype.getPrimaryPhone = function () {
        return this.primary_phone;
    };
    Patient.prototype.setPrimaryPhone = function (primaryPhone) {
        this.primary_phone = primaryPhone;
    };
    Patient.prototype.getPatientAreaId = function () {
        return Number(this.patient_area_id);
    };
    Patient.prototype.setPatientAreaId = function (patientAreaId) {
        this.patient_area_id = patientAreaId;
    };
    Patient.prototype.getAddress = function () {
        return this.address || '-';
    };
    Patient.prototype.setAddress = function (address) {
        this.address = address;
    };
    Patient.prototype.getBirthdate = function () {
        var birthdate = new Date(this.birthdate);
        return this.isDateValid(birthdate) ? birthdate : new Date();
    };
    Patient.prototype.getAge = function () {
        return this.computeAge(this.getBirthdate());
    };
    Patient.prototype.setBirthdate = function (birthdate) {
        this.birthdate = birthdate.toString();
    };
    Patient.prototype.getPatientDetails = function () {
        return plainToInstance(PatientDetails, this.details);
    };
    Patient.prototype.setPatientDetails = function (patientDetails) {
        this.details = instanceToPlain(patientDetails);
    };
    Patient.prototype.getReservations = function () {
        return plainToInstance(Reservation, this.reservations);
    };
    Patient.prototype.getAvailableBalance = function () {
        return Number(this.available_balance);
    };
    Patient.prototype.getPatientArea = function () {
        return plainToInstance(PatientArea, this.patient_area);
    };
    Patient.prototype.getTranslatedGender = function () {
        return this.translated_gender;
    };
    return Patient;
}(Model));
export default Patient;
