var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ApiService from './ApiService';
import UserService from './UserService';
import { ClinicReports, Create, DoctorReports, Index, PackageReports, PatientReports, Show, Update } from 'constants/actions';
import { ProductCategory, Product, Supplier, Stock, StockMovement, Account, AccountType, Transaction, Balance, FinancialPeriod, Document, Reports, Diagnose, FollowUp, MedicalRay, MedicalTest, Employee, MedicalMachine, Package, Appointments } from 'constants/resources';
var PermissionService = /** @class */ (function (_super) {
    __extends(PermissionService, _super);
    function PermissionService() {
        var _this = _super.call(this) || this;
        _this.userService = UserService.getInstance();
        return _this;
    }
    PermissionService.prototype.hasPermissionToAdd = function (resource) {
        return this.userService.hasPermission(Create, resource);
    };
    PermissionService.prototype.hasPermissionToEdit = function (resource) {
        return this.userService.hasPermission(Update, resource);
    };
    PermissionService.prototype.hasPermissionToIndex = function (resource) {
        return this.userService.hasPermission(Index, resource);
    };
    PermissionService.prototype.hasPermissionToShow = function (resource) {
        return this.userService.hasPermission(Show, resource);
    };
    PermissionService.prototype.hasAnyInventoryPermission = function () {
        return this.hasProductPermission() ||
            this.hasProductTypePermission() ||
            this.hasSupplierPermission() ||
            this.hasStockPermission() ||
            this.hasStockMovementPermission();
    };
    PermissionService.prototype.hasAnyFinanicePermission = function () {
        return this.hasAccountTypePermission() ||
            this.hasAccountPermission() ||
            this.hasTransactionPermission() ||
            this.hasBalancePermission() ||
            this.hasFinancialPeriodPermission() ||
            this.hasDocumentPermission();
    };
    PermissionService.prototype.hasAnyReportPermission = function () {
        return this.hasDoctorReportsPermission() ||
            this.hasPackageReportsPermission() ||
            this.hasPatientReportsPermission() ||
            this.hasClinicReportsPermission();
    };
    PermissionService.prototype.hasProductTypePermission = function () {
        return this.userService.hasPermission(Index, ProductCategory);
    };
    PermissionService.prototype.hasProductPermission = function () {
        return this.userService.hasPermission(Index, Product);
    };
    PermissionService.prototype.hasSupplierPermission = function () {
        return this.userService.hasPermission(Index, Supplier);
    };
    PermissionService.prototype.hasStockPermission = function () {
        return this.userService.hasPermission(Index, Stock);
    };
    PermissionService.prototype.hasStockMovementPermission = function () {
        return this.userService.hasPermission(Index, StockMovement);
    };
    PermissionService.prototype.hasAccountTypePermission = function () {
        return this.userService.hasPermission(Index, AccountType);
    };
    PermissionService.prototype.hasAccountPermission = function () {
        return this.userService.hasPermission(Index, Account);
    };
    PermissionService.prototype.hasBalancePermission = function () {
        return this.userService.hasPermission(Index, Balance);
    };
    PermissionService.prototype.hasFinancialPeriodPermission = function () {
        return this.userService.hasPermission(Index, FinancialPeriod);
    };
    PermissionService.prototype.hasDocumentPermission = function () {
        return this.userService.hasPermission(Index, Document);
    };
    PermissionService.prototype.hasTransactionPermission = function () {
        return this.userService.hasPermission(Index, Transaction);
    };
    PermissionService.prototype.hasDoctorReportsPermission = function () {
        return this.userService.hasPermission(DoctorReports, Reports);
    };
    PermissionService.prototype.hasPatientReportsPermission = function () {
        return this.userService.hasPermission(PatientReports, Reports);
    };
    PermissionService.prototype.hasPackageReportsPermission = function () {
        return this.userService.hasPermission(PackageReports, Reports);
    };
    PermissionService.prototype.hasClinicReportsPermission = function () {
        return this.userService.hasPermission(ClinicReports, Reports);
    };
    PermissionService.prototype.hasPatientLogPermission = function () {
        return this.userService.hasPermission(Index, Diagnose) ||
            this.userService.hasPermission(Index, FollowUp) ||
            this.userService.hasPermission(Index, MedicalRay) ||
            this.userService.hasPermission(Index, MedicalTest);
    };
    PermissionService.prototype.hasOtherPermission = function () {
        return this.userService.hasPermission(Index, Employee) ||
            this.userService.hasPermission(Index, MedicalMachine) ||
            this.userService.hasPermission(Index, Package);
    };
    PermissionService.prototype.hasPermissionToShowPatientTabs = function () {
        return this.hasPermissionToIndex(Package) || this.hasPermissionToIndex(Appointments);
    };
    PermissionService.getInstance = function () {
        if (!PermissionService.permissionService) {
            PermissionService.permissionService = new PermissionService();
        }
        return PermissionService.permissionService;
    };
    return PermissionService;
}(ApiService));
export default PermissionService;
