import { jsx as _jsx } from "react/jsx-runtime";
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Table } from 'antd';
var Users = function (props) {
    var userService = services.userService;
    var users = userService.useUsers().data;
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('users.id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('users.name') }),
            key: 'name',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }, 'name-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('users.mobile') }),
            key: 'mobile',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getMobile() }, 'mobile-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('users.title') }),
            key: 'title',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getTitle() }, 'title-' + index);
            }
        }
    ];
    return (_jsx("div", { className: "form-part", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: false, dataSource: users, locale: {
                emptyText: _jsx(EmptyComponent, {})
            } }) }));
};
export default localeTranslator(Users, 'settings');
