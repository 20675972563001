import { jsx as _jsx } from "react/jsx-runtime";
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import jwt_decode from 'jwt-decode';
import { get } from 'library/utilities/Storage';
import appConfig from 'config/app';
import App from './App';
import { withErrorBoundary } from 'react-error-boundary';
import './style.css';
var user_data = get('user');
var token = get('token');
var tokenData = token ? jwt_decode(token) : {};
var parsedUserData = JSON.parse(user_data || '{}');
var bugsnagClient = Bugsnag.start({
    apiKey: appConfig.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: '1.0.0',
    enabledReleaseStages: ['production'],
    metadata: {
        clinicCode: tokenData.clinic_code,
    },
    user: {
        id: parsedUserData.id,
        name: parsedUserData.name,
        email: parsedUserData.email,
    },
});
window.addEventListener('error', function (event) {
    var customError = new Error(event.error.message);
    bugsnagClient.notify(customError);
});
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            cacheTime: Infinity,
        },
    },
});
var ClinicManager = function () { return (_jsx(QueryClientProvider, { client: queryClient, children: _jsx(App, {}) })); };
var ComponentWithErrorBoundary = withErrorBoundary(ClinicManager, {
    fallback: _jsx(ClinicManager, {}),
    onError: function (error) {
        bugsnagClient.notify(error, function (event) {
            event.addMetadata('React Error Info', error);
        });
    }
});
// @ts-ignore
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(ComponentWithErrorBoundary, {}));
