import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Prescriptions from 'components/patients/PatientLog/Prescriptions';
import FollowUps from 'components/patients/PatientLog/FollowUps';
import MedicalTests from 'components/patients/PatientLog/MedicalTests';
import { useState } from 'react';
import PatientLogEntities from 'components/patients/PatientLog/PatientLogEntities';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './style.css';
import services from 'services';
import Diagnoses from 'components/patients/PatientLog/Diagnoses';
import AllPatientDetails from 'components/patients/PatientLog/PatientDetails';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Diagnose as diagnosisResource, FollowUp as FollowUpResource, MedicalTest as MedicalTestResource, Prescription as PrescriptionResource, } from 'constants/resources';
var PatientLog = function (props) {
    var _a = useState(false), showFollowUp = _a[0], setShowFollowUp = _a[1];
    var _b = useState(false), showMedicalTests = _b[0], setShowMedicalTests = _b[1];
    var _c = useState(false), showPrescriptions = _c[0], setShowPrescriptions = _c[1];
    var _d = useState(false), showDiagnoses = _d[0], setShowDiagnoses = _d[1];
    var _e = useState(false), showMedicalMachineUsages = _e[0], setShowMedicalMachineUsages = _e[1];
    var _f = useState(false), showImages = _f[0], setShowImages = _f[1];
    var _g = useState(false), showPatientMedicalDetails = _g[0], setShowPatientMedicalDetails = _g[1];
    var patientService = services.patientService, prescriptionService = services.prescriptionService, userService = services.userService, medicalTestService = services.medicalTestService, followUpService = services.followUpService, diagnoseService = services.diagnoseService, permissionService = services.permissionService;
    var patient = patientService.useGetPatient(props.patientId).data;
    var prescriptions = prescriptionService.usePatientPrescriptions(props.patientId).data;
    var medicalTests = medicalTestService.usePatientMedicalTests(props.patientId).data;
    var followUps = followUpService.usePatientFollowUps(props.patientId).data;
    var diagnoses = diagnoseService.usePatientDiagnoses(props.patientId).data;
    var loginData = userService.useLoginData().data;
    var createDiagnose = diagnoseService.useCreateDiagnose().mutate;
    var createFollowUp = followUpService.useCreateFollowUp().mutate;
    var createMedicalTest = medicalTestService.useCreateMedicalTests().mutate;
    var createPrescription = prescriptionService.useCreatePrescription().mutate;
    var updatePatientDetails = patientService.useUpdatePatient().mutate;
    var onAddDiagnose = function (diagnose) {
        diagnose.setAppointmentId(props.appointmentId);
        diagnose.setPatientId(props.patientId);
        createDiagnose({ diagnose: diagnose, successMessage: props.translate('messages.diagnoseAddedSuccessfully') });
    };
    var onAddFollowUp = function (followUp) {
        followUp.setAppointmentId(props.appointmentId);
        followUp.setPatientId(props.patientId);
        createFollowUp({ followUp: followUp, successMessage: props.translate('messages.followUpAddedSuccessfully') });
    };
    var onAddMedicalTest = function (medicalTest) {
        medicalTest.setAppointmentId(props.appointmentId);
        medicalTest.setPatientId(props.patientId);
        createMedicalTest({ medicalTest: medicalTest, successMessage: props.translate('messages.medicalTestAddedSuccessfully') });
    };
    var onUpdatePatientDetails = function (patient) { return updatePatientDetails({
        patient: patient,
        successMessage: props.translate('messages.patientDetailsUpdatedSuccessfully')
    }); };
    var onAddPrescription = function (prescription) {
        prescription.setAppointmentId(props.appointmentId);
        prescription.setPatientId(props.patientId);
        createPrescription({ prescription: prescription, successMessage: props.translate('messages.prescriptionAddedSuccessfully') });
    };
    var showPatientLogEntities = function () { return !showFollowUp && !showMedicalTests &&
        !showPrescriptions && !showDiagnoses && !showMedicalMachineUsages && !showImages &&
        !showPatientMedicalDetails; };
    var getIcon = function (lng) {
        if (lng === void 0) { lng = 'en'; }
        return (loginData === null || loginData === void 0 ? void 0 : loginData.lang) != lng ?
            _jsx(RightOutlined, { style: { fontSize: '12px' } }) : _jsx(LeftOutlined, { style: { fontSize: '12px' } });
    };
    var getDirectionClass = function () { return (loginData === null || loginData === void 0 ? void 0 : loginData.lang) != 'en' ?
        'rtl-log' : 'ltr-log'; };
    return (_jsxs("div", { className: "patient-log ".concat(getDirectionClass()), children: [showFollowUp && followUps && permissionService.hasPermissionToIndex(FollowUpResource) &&
                _jsx(FollowUps, { setShowFollowUp: setShowFollowUp, followUps: followUps, createFollowUp: onAddFollowUp, arrowIcon: getIcon() }), showMedicalTests && medicalTests && permissionService.hasPermissionToIndex(MedicalTestResource) &&
                _jsx(MedicalTests, { setShowMedicalTest: setShowMedicalTests, medicalTests: medicalTests, createMedicalTest: onAddMedicalTest, arrowIcon: getIcon() }), showPrescriptions && prescriptions && permissionService.hasPermissionToIndex(PrescriptionResource) &&
                _jsx(Prescriptions, { setShowPrescriptions: setShowPrescriptions, prescriptions: prescriptions, createPrescription: onAddPrescription, arrowIcon: getIcon() }), showDiagnoses && diagnoses && permissionService.hasPermissionToIndex(diagnosisResource) &&
                _jsx(Diagnoses, { setShowDiagnoses: setShowDiagnoses, diagnoses: diagnoses, createDiagnose: onAddDiagnose, arrowIcon: getIcon() }), showPatientMedicalDetails && patient &&
                _jsx(AllPatientDetails, { setShowPatientDetails: setShowPatientMedicalDetails, updatePatientDetails: onUpdatePatientDetails, patient: patient, arrowIcon: getIcon() }), showPatientLogEntities() &&
                _jsx(PatientLogEntities, { setShowMedicalTests: setShowMedicalTests, setShowFollowUps: setShowFollowUp, setShowPrescriptions: setShowPrescriptions, setShowDiagnoses: setShowDiagnoses, setShowMedicalMachineUsages: setShowMedicalMachineUsages, setShowImages: setShowImages, setShowPatientMedicalDetails: setShowPatientMedicalDetails, arrowIcon: getIcon('ar') })] }));
};
export default localeTranslator(PatientLog, '');
