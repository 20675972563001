import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
import services from 'services';
var PurchaseMoveForm = function (props) {
    var supplierService = services.supplierService;
    var suppliers = supplierService.useSupplierListing().data;
    var onChangeEntityId = function (entityId) { return entityId && props.stockMovement.setEntityId(Number(entityId)); };
    var getSupplierOptions = function () {
        return (suppliers === null || suppliers === void 0 ? void 0 : suppliers.map(function (supplier) { return ({
            value: supplier.getId(),
            title: supplier.getName()
        }); })) || [];
    };
    return (_jsx(_Fragment, { children: _jsx(FormSelect, { label: props.translate('supplier'), name: "supplier", rules: [
                { required: true, message: props.translate('errors.supplier') }
            ], placeHolder: props.translate('supplier'), onSelect: onChangeEntityId, options: getSupplierOptions(), initialValue: props.stockMovement.getEntityId() || null }) }));
};
export default localeTranslator(PurchaseMoveForm, 'stockMovementForm');
