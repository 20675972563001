var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { plainToClass } from 'class-transformer';
import ClinicSettings from './ClinicSettings';
var Clinic = /** @class */ (function (_super) {
    __extends(Clinic, _super);
    function Clinic() {
        return _super.call(this) || this;
    }
    Clinic.prototype.getId = function () {
        return Number(this.id);
    };
    Clinic.prototype.getName = function () {
        return this.name;
    };
    Clinic.prototype.getSettings = function () {
        return plainToClass(ClinicSettings, this.settings);
    };
    Clinic.prototype.getCode = function () {
        return this.code;
    };
    return Clinic;
}(Model));
export default Clinic;
