var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var TransactionReportsPerClinic = /** @class */ (function (_super) {
    __extends(TransactionReportsPerClinic, _super);
    function TransactionReportsPerClinic() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TransactionReportsPerClinic.prototype.getClinicName = function () {
        return this.clinic_name;
    };
    TransactionReportsPerClinic.prototype.getClinicId = function () {
        return Number(this.clinic_id);
    };
    TransactionReportsPerClinic.prototype.getAvgTransactions = function () {
        return this.roundNumber(this.avg_transactions);
    };
    TransactionReportsPerClinic.prototype.getTotalTransactions = function () {
        return this.roundNumber(this.total_transactions);
    };
    return TransactionReportsPerClinic;
}(Model));
export default TransactionReportsPerClinic;
