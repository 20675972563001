var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass, plainToInstance } from 'class-transformer';
import Model from 'models/Model';
import { Patient, Doctor, Clinic, AppointmentDetail, AppointmentConsumption } from 'models';
import dayjs from 'dayjs';
var Appointment = /** @class */ (function (_super) {
    __extends(Appointment, _super);
    function Appointment() {
        var _this = _super.call(this) || this;
        _this.setVisitTime(new Date());
        _this.setTimeSlots(1);
        return _this;
        // this.setDetails([]);
    }
    Appointment.prototype.getId = function () {
        return Number(this.id);
    };
    Appointment.prototype.getDoctor = function () {
        return plainToClass(Doctor, this.doctor);
    };
    Appointment.prototype.getDoctorId = function () {
        return Number(this.doctor_id);
    };
    Appointment.prototype.setDoctor = function (doctor) {
        this.doctor = doctor;
        this.doctor_id = doctor.getId();
    };
    Appointment.prototype.getClinic = function () {
        return plainToClass(Clinic, this.clinic);
    };
    Appointment.prototype.setClinic = function (clinic) {
        this.clinic = clinic;
    };
    Appointment.prototype.getFormattedAppointmentTime = function () {
        return this.dateForamtter(new Date(this.time), 'DD/MM/YYYY');
    };
    Appointment.prototype.getVisitTimeObject = function () {
        return new Date(this.time);
    };
    Appointment.prototype.setVisitTime = function (time) {
        this.time = time.toString();
    };
    Appointment.prototype.getAppointmentTime = function () {
        return dayjs(this.time);
    };
    Appointment.prototype.setDate = function (date) {
        this.choosenDate = date;
        var visitDate = this.getVisitTimeObject();
        visitDate.setDate(date.getDate());
        visitDate.setMonth(date.getMonth());
        visitDate.setFullYear(date.getFullYear());
        this.setVisitTime(visitDate);
    };
    Appointment.prototype.setTime = function (time) {
        this.choosenTime = time;
        var visitTime = this.getVisitTimeObject();
        visitTime.setHours(time.getHours(), time.getMinutes(), 0, 0);
        this.setVisitTime(visitTime);
    };
    Appointment.prototype.getDate = function () {
        return this.choosenDate;
    };
    Appointment.prototype.getTime = function () {
        return this.choosenTime;
    };
    Appointment.prototype.getStartedAt = function () {
        return this.dateForamtter(new Date(this.started_at), 'hh:mm A');
    };
    Appointment.prototype.setStartedAt = function () {
        this.started_at = new Date().toString();
    };
    Appointment.prototype.getFinishedAt = function () {
        return this.dateForamtter(new Date(this.finished_at), 'hh:mm A');
    };
    Appointment.prototype.setFinishedAt = function () {
        this.finished_at = new Date().toString();
    };
    Appointment.prototype.getNote = function () {
        return this.note;
    };
    Appointment.prototype.setNote = function (note) {
        this.note = note;
    };
    Appointment.prototype.getCancelledAt = function () {
        return this.dateForamtter(new Date(this.cancelled_at), 'DD/MM/YYYY hh:mm A');
    };
    Appointment.prototype.setCancelledAt = function () {
        this.cancelled_at = new Date().toString();
    };
    Appointment.prototype.getCancellationReason = function () {
        return this.cancellation_reason;
    };
    Appointment.prototype.setCancellationReason = function (cancellation_reason) {
        this.cancellation_reason = cancellation_reason;
    };
    Appointment.prototype.getCancellationfee = function () {
        return Number(this.cancellation_fee);
    };
    Appointment.prototype.setCancellation = function (cancellationFee) {
        this.cancellation_fee = Number(cancellationFee);
    };
    Appointment.prototype.getStatus = function () {
        return this.status;
    };
    Appointment.prototype.getDisplayedStatus = function () {
        return this.displayed_status;
    };
    Appointment.prototype.getNumber = function () {
        return Number(this.number || 0);
    };
    Appointment.prototype.getArrivedAt = function () {
        return this.dateForamtter(new Date(this.arrived_at), 'DD/MM/YYYY hh:mm A');
    };
    Appointment.prototype.setArrivedAt = function () {
        this.arrived_at = new Date().toString();
    };
    Appointment.prototype.getTimeSlots = function () {
        return Number(this.time_slots);
    };
    Appointment.prototype.setTimeSlots = function (timeSlots) {
        if (timeSlots === void 0) { timeSlots = 1; }
        this.time_slots = Number(timeSlots);
    };
    // getDetails(): VisitDetail[] {
    //   return plainToClass(VisitDetail, this.visit_details as VisitDetail[]);
    // }
    // setDetails(details: VisitDetail[]): void {
    //   this.visit_details = details;
    // }
    // addDetail(selectedPackage: Package, remainder?: number): void {
    //   const visitDetail = new VisitDetail(selectedPackage);
    //   remainder && visitDetail.setRemainder(remainder);
    //   this.visit_details.push(visitDetail);
    // }
    // removeDetail(packageId: number | null, detailId?: number): void {
    //   if (packageId) {
    //     this.visit_details = this.visit_details.filter(vd => {
    //       return vd.getPackage().getId() !== Number(packageId);
    //     });
    //   }  
    //   if (detailId) {
    //     this.visit_details = this.visit_details.filter(vd => {
    //       return vd.getId() !== Number(detailId);
    //     });
    //   }
    // }
    Appointment.prototype.isTimeValid = function () {
        return !!this.getDate() && !!this.getTime() && !!this.getTimeSlots();
    };
    // isHasDetails(): boolean {
    //   return !!this.getDetails().length;
    // }
    // isValidForCreation (): boolean {
    //   return this.isTimeValid() && !!this.getDoctor() && this.isHasDetails();
    // }
    Appointment.prototype.getClinicId = function () {
        return Number(this.clinic_id);
    };
    Appointment.prototype.setClinicId = function (clinicId) {
        this.clinic_id = Number(clinicId);
    };
    Appointment.prototype.setPatient = function (patient) {
        this.patient = plainToClass(Patient, patient);
    };
    Appointment.prototype.getPatient = function () {
        return plainToClass(Patient, this.patient);
    };
    Appointment.prototype.setPatientId = function (patientId) {
        this.patient_id = patientId;
    };
    Appointment.prototype.getPatientId = function () {
        return Number(this.patient_id);
    };
    // getSettlements(): Settlement[] {
    //   return plainToClass(Settlement, this.settlements as Settlement[]);
    // }
    Appointment.prototype.getAppointmentDetails = function () {
        return plainToClass(AppointmentDetail, this.appointment_details);
    };
    Appointment.prototype.setAppointmentDetail = function (appointmentDetail) {
        this.appointment_details.push(appointmentDetail);
    };
    Appointment.prototype.replaceAppointmentDetails = function (appointmentDetails) {
        this.appointment_details = appointmentDetails;
    };
    Appointment.prototype.addDetail = function (reservationPackageId, quantity) {
        var appointmentDetail = new AppointmentDetail();
        appointmentDetail.setReservationPackageId(reservationPackageId);
        quantity && appointmentDetail.setQuantity(quantity);
        this.appointment_details.push(appointmentDetail);
    };
    Appointment.prototype.removeDetail = function (reservationPackageId, detailId) {
        if (reservationPackageId) {
            this.appointment_details = this.appointment_details.filter(function (appointmentDetail) {
                return appointmentDetail.getReservationPackage().getId() !== Number(reservationPackageId);
            });
        }
        if (detailId) {
            this.appointment_details = this.appointment_details.filter(function (appointmentDetail) {
                return appointmentDetail.getId() !== Number(detailId);
            });
        }
    };
    Appointment.prototype.setAppointmentConsumptions = function (appointmentConsumption) {
        this.appointment_consumptions = plainToInstance(AppointmentConsumption, appointmentConsumption);
    };
    Appointment.prototype.getAppointmentConsumptions = function () {
        return this.appointment_consumptions;
    };
    Appointment.prototype.getInEditableStatus = function () {
        return this.in_editable_status;
    };
    return Appointment;
}(Model));
export default Appointment;
