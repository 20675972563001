var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { Stock } from 'models';
import { plainToInstance } from 'class-transformer';
var StockMovement = /** @class */ (function (_super) {
    __extends(StockMovement, _super);
    function StockMovement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StockMovement.prototype.getId = function () {
        return Number(this.id);
    };
    StockMovement.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    StockMovement.prototype.setQuantity = function (quantity) {
        this.quantity = Number(quantity);
    };
    StockMovement.prototype.getPrice = function () {
        return Number(this.price);
    };
    StockMovement.prototype.setPrice = function (price) {
        this.price = Number(price);
    };
    StockMovement.prototype.getUnitPrice = function () {
        return Number(this.unit_price);
    };
    StockMovement.prototype.getTotalPrice = function () {
        return Number(this.total_price);
    };
    StockMovement.prototype.getPriceType = function () {
        return this.price_type;
    };
    StockMovement.prototype.setPriceType = function (priceType) {
        this.price_type = priceType;
    };
    StockMovement.prototype.getDisplayedPriceType = function () {
        return this.displayed_price_type;
    };
    StockMovement.prototype.getStatus = function () {
        return this.status;
    };
    StockMovement.prototype.getDisplayedStatus = function () {
        return this.displayed_status;
    };
    StockMovement.prototype.getType = function () {
        return this.type;
    };
    StockMovement.prototype.getDisplayedType = function () {
        return this.displayed_type;
    };
    StockMovement.prototype.getStock = function () {
        return plainToInstance(Stock, this.stock);
    };
    StockMovement.prototype.setStock = function (stock) {
        this.stock = stock;
    };
    StockMovement.prototype.getEntityName = function () {
        return this.entity_name;
    };
    StockMovement.prototype.getFormattedEndedAt = function () {
        return this.dateForamtter(new Date(this.ended_at), 'DD/MM/YYYY');
    };
    StockMovement.prototype.getStockId = function () {
        return Number(this.stock_id);
    };
    StockMovement.prototype.setStockId = function (stockId) {
        this.stock_id = Number(stockId);
    };
    StockMovement.prototype.getEntityId = function () {
        return Number(this.entity_id);
    };
    StockMovement.prototype.setEntityId = function (entityId) {
        this.entity_id = Number(entityId);
    };
    StockMovement.prototype.setProductId = function (productId) {
        this.product_id = Number(productId);
    };
    StockMovement.prototype.setMoveType = function (moveType) {
        this.move_type = moveType;
    };
    StockMovement.prototype.getMoveType = function () {
        return this.move_type;
    };
    StockMovement.prototype.setStartedAt = function (startedAt) {
        this.started_at = startedAt.toString();
    };
    return StockMovement;
}(Model));
export default StockMovement;
