import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Drawer, Form } from 'antd';
import ChangeAvailability from 'components/forms/packagePrices/ChangeAvailability';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
import '../../style.css';
var ChangePackagePriceAvailabilityDrawer = function (props) {
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var packagePriceService = services.packagePriceService;
    var _b = packagePriceService.useUpdatePackagePriceAvailability(), updateAvailability = _b.mutate, isAvailabilityUpdated = _b.isSuccess, errorResponse = _b.isError;
    useEffect(function () {
        isAvailabilityUpdated && onClose();
    }, [isAvailabilityUpdated]);
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    var onSubmit = function () {
        setDisabled(true);
        updateAvailability({ packagePrice: props.packagePrice, successMessage: 'messages.updatePackagePriceAvailabilitySucceed' });
    };
    return (_jsx(Drawer, { title: props.translate('drawers.changeAvailability'), placement: "bottom", open: props.visiable, size: "default", onClose: onClose, className: "transaction-drawer", children: _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: [_jsx(ChangeAvailability, { packagePrice: props.packagePrice }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) })] }) }, "bottom"));
};
export default localeTranslator(ChangePackagePriceAvailabilityDrawer, 'showPackage');
