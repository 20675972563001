// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remove-padding {
  padding-bottom: 0px !important;
}

.cards {
  align-items: center;
}

.queue {
  display: flex;
  flex-direction: row;
  padding-inline-start: 70px;
}

/* .ant-space-align-center {
  align-items: baseline;
} */

.cardStatus {
  display: flex;
  align-items: center;
  height: 35px;
  padding-left: 13px;
}

.seperator {
  background-color: #cbd6e3;
  height: 1px;
  margin: 5px 0px 5px 0px;
}

.ant-input-group>.ant-input-rtl:first-child {
  border-radius: 0 5px 5px 0 !important;
}
.doctor-selector>.ant-btn-compact-first-item {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/style.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;;GAEG;;AAEH;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,qCAAqC;AACvC;AACA;EACE,aAAa;AACf","sourcesContent":[".remove-padding {\n  padding-bottom: 0px !important;\n}\n\n.cards {\n  align-items: center;\n}\n\n.queue {\n  display: flex;\n  flex-direction: row;\n  padding-inline-start: 70px;\n}\n\n/* .ant-space-align-center {\n  align-items: baseline;\n} */\n\n.cardStatus {\n  display: flex;\n  align-items: center;\n  height: 35px;\n  padding-left: 13px;\n}\n\n.seperator {\n  background-color: #cbd6e3;\n  height: 1px;\n  margin: 5px 0px 5px 0px;\n}\n\n.ant-input-group>.ant-input-rtl:first-child {\n  border-radius: 0 5px 5px 0 !important;\n}\n.doctor-selector>.ant-btn-compact-first-item {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
