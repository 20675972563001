import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Row, Col, Form, Space, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
var useState = React.useState, useEffect = React.useEffect;
var LanguageForm = function (props) {
    var form = Form.useForm()[0];
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var onChangeLanguage = function (value) { return props.user.setLanguage(value.toString()); };
    var onSubmit = function () {
        setDisabled(true);
        props.updateLanguage({ user: props.user });
    };
    useEffect(function () {
        if (props.disableReset) {
            setDisabled(false);
        }
    }, [props.disableReset]);
    var getLanguageOptions = function () {
        return props.languages.map(function (lang) { return ({
            value: lang.getValue(),
            title: lang.getName()
        }); });
    };
    return (_jsxs("div", { className: "form-part", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('languages.changeLanguage') }), _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: _jsxs(Space, { direction: 'vertical', size: 60, style: { width: '100%' }, children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormSelect, { label: props.translate('languages.label'), name: "language", rules: [], placeHolder: props.translate('languages.placeHodlder'), onSelect: onChangeLanguage, options: getLanguageOptions(), initialValue: props.user.getLanguage() || null }) }) }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] }) })] }));
};
export default localeTranslator(LanguageForm, 'settings');
