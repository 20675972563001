var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Menu, Dropdown } from 'antd';
import { RightOutlined, UserOutlined, LeftOutlined } from '@ant-design/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
var DoctorPicker = function (props) {
    var currentUser = services.userService.useCurrentUser().data;
    var getPickerIcon = function () {
        return (currentUser === null || currentUser === void 0 ? void 0 : currentUser.getLanguage()) != 'ar' ? _jsx(RightOutlined, {}) : _jsx(LeftOutlined, {});
    };
    var handleMenuClick = function (val) {
        var doctorsIdObj = props.doctors.reduce(function (accum, val) {
            var _a;
            return __assign(__assign({}, accum), (_a = {}, _a[val.getId()] = val, _a));
        }, {});
        /*remove required element from doctors */
        var index = props.doctors.findIndex(function (doctor) { return doctor.getId() == val.key; });
        index !== -1 && props.doctors.splice(index, 1);
        /*add element the target element to the first of the array */
        props.doctors.unshift(doctorsIdObj[val.key]);
        props.setDoctors && props.setDoctors(__spreadArray([], props.doctors, true));
    };
    var menu = (_jsx(Menu, { onClick: function (val) { return handleMenuClick(val); }, children: props.doctors.map(function (doctor, index) {
            var _a;
            if (index > 2) {
                return (_jsx(Menu.Item, { icon: _jsx(UserOutlined, {}), children: (_a = doctor.getUser()) === null || _a === void 0 ? void 0 : _a.getName() }, doctor.getId()));
            }
        }) }));
    return _jsx(Dropdown.Button, { overlay: menu, placement: "bottomCenter", icon: getPickerIcon(), className: 'doctor-selector' });
};
export default localeTranslator(DoctorPicker, 'ay7aga');
