import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { ReservationPackage as ReservationPackageResource } from 'constants/resources';
import './style.css';
import NormalHeader from 'components/PageHeader/NormalHeader';
var ReservationPackages = function (props) {
    var navigate = useNavigate();
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var reservationPackageService = services.reservationPackageService;
    var data = reservationPackageService.useReservationPackages(pageNumber).data;
    var goToReservationPackage = function (reservationPackageId) { return function () { return navigate("/reservation-packages/".concat(reservationPackageId)); }; };
    var menu = function (reservationPackageId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToReservationPackage(reservationPackageId), children: props.translate('common:buttons.show') }, "1") }));
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientName') }),
            key: 'patientName',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getPatient().getName() }, 'patientName-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientMobile') }),
            width: '14%',
            key: 'patientMobile',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getPatient().getPrimaryPhone() }, 'patientMobile-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('packageName') }),
            width: '14%',
            key: 'packageName',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getPackagePrice().getPackageName() }, 'packageName-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('status') }),
            width: '14%',
            key: 'status',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getDisplayedStatus() }, 'status-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('price') }),
            key: 'price',
            render: function (text, record, index) {
                return _jsx("ul", { className: "body---14pt-R table-body-content", children: record.getPackagePrice().getTotalPrice() * record.getRepeated() }, 'price-' + index);
            }
        },
        {
            key: 'action',
            render: function (text, record, index) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" }, "action-".concat(index));
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var goToNewReservationPackageForm = function () { return navigate('/reservation-packages/new'); };
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header.reservationPackagesList'), addButtonText: props.translate('common:buttons.newReservationPackage'), addButtonFunction: goToNewReservationPackageForm, addButtonClasses: "widder-normal-button Header-16-M", resource: ReservationPackageResource }), _jsx("div", { className: "index-page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, 
                    // scroll={{ x: 1300 }}
                    dataSource: data === null || data === void 0 ? void 0 : data.reservationPackages, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(ReservationPackages, 'reservationPackages');
