import { jsx as _jsx } from "react/jsx-runtime";
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import EntityHeader from 'components/PageHeader/EntityHeader';
import { Appointments, Patient, ReservationPackage, Transaction } from 'constants/resources';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import services from 'services';
var Header = function (props) {
    var navigate = useNavigate();
    var id = useParams().id;
    var onAddReservationPackage = function () { return props.setShowReservationPackagesDrawer(true); };
    var onAddAppointment = function () { return props.setShowAppointmentDrawer(true); };
    var onAddTransaction = function () { return props.setShowTransactionDrawer(true); };
    var onEdit = function () { return navigate("/patients/".concat(id, "/update")); };
    var permissionService = services.permissionService;
    var itemButtons = function () {
        var items = [];
        if (permissionService.hasPermissionToAdd(ReservationPackage)) {
            items.push({
                key: '1',
                label: (_jsx("a", { target: "_blank", rel: "noopener noreferrer", onClick: onAddReservationPackage, children: props.translate('header.addReservation') })),
            });
        }
        if (permissionService.hasPermissionToAdd(Appointments)) {
            items.push({
                key: '2',
                label: (_jsx("a", { target: "_blank", rel: "noopener noreferrer", onClick: onAddAppointment, children: props.translate('header.addAppointment') })),
            });
        }
        if (permissionService.hasPermissionToAdd(Transaction)) {
            items.push({
                key: '3',
                label: (_jsx("a", { target: "_blank", rel: "noopener noreferrer", onClick: onAddTransaction, children: props.translate('header.addTransaction') })),
            });
        }
        return items;
    };
    var buttons = function () {
        if (!itemButtons.length) {
            return;
        }
        return (_jsx(Dropdown, { menu: { items: itemButtons() }, placement: "bottom", arrow: { pointAtCenter: true }, children: _jsx(Button, { className: 'normal-button Header-16-M', icon: _jsx(CaretDownOutlined, {}), children: props.translate('common:buttons.actions') }) }));
    };
    return (_jsx(EntityHeader, { children: buttons(), title: props.translate('header.patientProfile'), editFn: onEdit, resource: Patient }));
};
export default localeTranslator(Header, 'showPatient');
