var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { Permission } from 'models';
import { plainToInstance } from 'class-transformer';
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User() {
        return _super.call(this) || this;
    }
    User.prototype.getId = function () {
        return Number(this.id);
    };
    User.prototype.getName = function () {
        return this.name;
    };
    User.prototype.setName = function (name) {
        this.name = name;
    };
    User.prototype.getEmail = function () {
        return this.email;
    };
    User.prototype.setEmail = function (email) {
        this.email = email;
    };
    User.prototype.getBirthDay = function () {
        return new Date(this.birthday);
    };
    User.prototype.getAge = function () {
        return this.computeAge(this.getBirthDay());
    };
    User.prototype.setBirthDay = function (birthday) {
        this.birthday = birthday.toString();
    };
    User.prototype.getAddress = function () {
        return this.address;
    };
    User.prototype.setAddress = function (address) {
        this.address = address;
    };
    User.prototype.getProfileId = function () {
        return Number(this.profile_id);
    };
    User.prototype.getProfileType = function () {
        return this.profile_type;
    };
    User.prototype.getRoleId = function () {
        return Number(this.role_id);
    };
    User.prototype.getRoleName = function () {
        return this.role_name;
    };
    User.prototype.getHomePhone = function () {
        return this.home_phone;
    };
    User.prototype.setHomePhone = function (homePhone) {
        this.home_phone = homePhone;
    };
    User.prototype.getMobile = function () {
        return this.primary_phone;
    };
    User.prototype.setMobile = function (primary_phone) {
        this.primary_phone = primary_phone;
    };
    User.prototype.canSeeInvoices = function () {
        return this.see_invoices;
    };
    User.prototype.canSeeFinancial = function () {
        return this.see_financial;
    };
    User.prototype.canApproveTransactionUpdates = function () {
        return this.can_approve;
    };
    User.prototype.canMakeTransactions = function () {
        return this.can_make_transaction;
    };
    User.prototype.getLanguage = function () {
        return this.language;
    };
    User.prototype.setLanguage = function (language) {
        this.language = language;
    };
    User.prototype.getGender = function () {
        return this.gender;
    };
    User.prototype.getTranslatedGender = function () {
        return this.translated_gender;
    };
    User.prototype.setGender = function (gender) {
        this.gender = gender;
    };
    User.prototype.setPassword = function (password) {
        this.password = password;
    };
    User.prototype.setPasswordConfirmation = function (passwordConfirmation) {
        this.password_confirmation = passwordConfirmation;
    };
    User.prototype.getToken = function () {
        return this.token;
    };
    User.prototype.setToken = function (token) {
        this.token = token;
    };
    User.prototype.getColor = function () {
        return this.color;
    };
    User.prototype.setColor = function (color) {
        this.color = color;
    };
    User.prototype.getPermissions = function () {
        return plainToInstance(Permission, this.permissions);
    };
    User.prototype.setOldPassword = function (oldPassword) {
        this.old_password = oldPassword;
    };
    User.prototype.getTitle = function () {
        return this.title;
    };
    return User;
}(Model));
export default User;
