var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { Supplier, Telephone } from 'models';
import { plainToInstance } from 'class-transformer';
var SupplierBranch = /** @class */ (function (_super) {
    __extends(SupplierBranch, _super);
    function SupplierBranch() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SupplierBranch.prototype.getId = function () {
        return Number(this.id);
    };
    SupplierBranch.prototype.getAddress = function () {
        return this.address;
    };
    SupplierBranch.prototype.setAddress = function (address) {
        this.address = address;
    };
    SupplierBranch.prototype.getSupplier = function () {
        return plainToInstance(Supplier, this.supplier);
    };
    SupplierBranch.prototype.setSupplier = function (supplier) {
        this.supplier = supplier;
    };
    SupplierBranch.prototype.getSupplierId = function () {
        return Number(this.supplier_id);
    };
    SupplierBranch.prototype.setSupplierId = function (supplierId) {
        this.supplier_id = supplierId;
    };
    SupplierBranch.prototype.getTelephones = function () {
        return plainToInstance(Telephone, this.telephones);
    };
    SupplierBranch.prototype.setTelephones = function (telephones) {
        this.telephones = telephones;
    };
    SupplierBranch.prototype.getMainTelephone = function () {
        if (!this.getTelephones().length) {
            return '';
        }
        var mainTelephones = this.getTelephones().filter(function (tel) { return (tel === null || tel === void 0 ? void 0 : tel.getType()) == 'phone'; });
        return mainTelephones.length ? mainTelephones[0].getNumber() : '';
    };
    return SupplierBranch;
}(Model));
export default SupplierBranch;
