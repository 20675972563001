var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Drawer, Form, Button, Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { AppointmentDetail } from 'models';
import { useEffect, useState } from 'react';
import services from 'services';
import VisitPackageCard from 'components/cards/VisitPackageCard';
import 'pages/ShowAppointment/style.css';
var PackagesDrawer = function (props) {
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var appointmentDetailService = services.appointmentDetailService, patientService = services.patientService;
    var _a = appointmentDetailService.useCreateBlukAppointmentDetails(), createBulkAppointmentDetails = _a.mutate, isPackageAssigned = _a.isSuccess;
    var allReservationPackages = patientService.useGetPatientReservationPackages(props.appointment.getPatient().getId(), true).data;
    var _b = useState([]), selectedReservationPackageIds = _b[0], setSelectedReservationPackageIds = _b[1];
    var _c = useState([]), reservationPackages = _c[0], setReservationPackages = _c[1];
    var onSelectPackage = function (value, reservationPackage) {
        setReservationPackages(__spreadArray(__spreadArray([], reservationPackages, true), [reservationPackage], false));
        setSelectedReservationPackageIds(__spreadArray(__spreadArray([], selectedReservationPackageIds, true), [reservationPackage.getId()], false));
    };
    var onDeselectPackage = function (value, reservationPackage) {
        setReservationPackages(reservationPackages.filter(function (rp) { return rp.getId() != reservationPackage.getId(); }));
        setSelectedReservationPackageIds(selectedReservationPackageIds.filter(function (id) { return id != reservationPackage.getId(); }));
    };
    useEffect(function () { isPackageAssigned && onClose(); }, [isPackageAssigned]);
    var onSubmit = function () {
        var appointmentDetails = [];
        reservationPackages.forEach(function (rp) {
            var detail = new AppointmentDetail();
            detail.setAppointmentId(props.appointment.getId());
            detail.setReservationPackageId(rp.getId());
            appointmentDetails.push(detail);
        });
        createBulkAppointmentDetails({ appointmentDetails: appointmentDetails });
    };
    // const reservationPackagesRemainder = props.reservationPackagesPrices
    //   .reduce<Record<string, number>>((obj, rpPayment) => ({
    //     ...obj,
    //     [`${rpPayment.getId()}`]: rpPayment.getDebit()
    //   }), {});
    // const getReservationPackageRemainder = (rp: ReservationPackage) =>
    //   reservationPackagesRemainder[
    //     ReservationPackagePayment.computeIdFormate(rp.getReservationId(), rp.getPackage().getId())
    //   ];
    var getAvailableReservationPackages = function () {
        var currentReservationPackagesIds = props.appointment.getAppointmentDetails().map(function (detail) { return detail.getReservationPackage().getId(); });
        return allReservationPackages ?
            allReservationPackages.filter(function (rp) { return !currentReservationPackagesIds.includes(rp.getId()); })
            :
                [];
    };
    return (_jsx(Drawer, { title: props.translate('packagesInfo.title'), placement: "bottom", open: props.visiable, size: "default", onClose: onClose, className: "transaction-drawer", children: _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: [_jsx(Row, { className: "packages-cards", children: getAvailableReservationPackages().map(function (rp) {
                        var isPackageSelected = selectedReservationPackageIds.includes(rp.getId());
                        var className = !isPackageSelected ? 'normal-card-color' : 'selected-card-color';
                        return (_jsx(Col, { lg: 6, style: { marginInlineEnd: 1 }, children: _jsx(VisitPackageCard, { reservationPackage: rp, onDeselectPackage: onDeselectPackage, onSelectPackage: onSelectPackage, backgroundClass: className, isSelected: isPackageSelected }) }));
                    }) }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }, "bottom"));
};
export default localeTranslator(PackagesDrawer, 'showVisit');
