var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { classToPlain, instanceToPlain, plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { Item, Package, ReservationPackage, ReservationTransaction, Visit, VisitDetail } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from './ApiService';
import DoctorService from './DoctorService';
import UnitMeasurementService from './UnitMeasurementService';
import { AFTER_WORK, CANCELLED, CONFIRMED, IN_VISIT, PENDING, UN_REACHED, WAITING } from 'constants/VisitStatuses';
import { useNavigate } from 'react-router';
var ClinicVisitService = /** @class */ (function (_super) {
    __extends(ClinicVisitService, _super);
    function ClinicVisitService() {
        var _a;
        var _this = _super.call(this) || this;
        _this.clinicVisitsRoot = 'clinic_visits';
        _this.successCreationMessage = '';
        _this.patientId = 0;
        _this.routes = (_a = {},
            _a[PENDING] = 'pending',
            _a[UN_REACHED] = 'unreached',
            _a[WAITING] = 'waiting',
            _a[CONFIRMED] = 'confirmed',
            _a[IN_VISIT] = 'start',
            _a[AFTER_WORK] = 'after-work',
            _a[CANCELLED] = 'cancel',
            _a);
        _this.doctorService = DoctorService.getInstance();
        _this.unitMeasurementService = UnitMeasurementService.getInstance();
        return _this;
    }
    ClinicVisitService.prototype.useGetClinicVisit = function (id) {
        var _this = this;
        var _a = this.doctorService.useDoctors(), doctors = _a.data, isDoctorsFetched = _a.isSuccess;
        var _b = this.unitMeasurementService.useUnitMeasurements(), unitMeasurements = _b.data, isUnitMeasurementFetched = _b.isSuccess;
        return useQuery([queries.clinicVisits, id], function () {
            return _this.get("".concat(_this.clinicVisitsRoot, "/").concat(id));
        }, {
            retry: false,
            enabled: isDoctorsFetched && isUnitMeasurementFetched,
            select: function (response) {
                var data = response.data;
                var doctorsById = doctors === null || doctors === void 0 ? void 0 : doctors.reduce(function (obj, doctor) {
                    var _a;
                    return (__assign(__assign({}, obj), (_a = {}, _a[doctor.getId()] = doctor, _a)));
                }, {});
                var unitMeasurementsById = unitMeasurements === null || unitMeasurements === void 0 ? void 0 : unitMeasurements.reduce(function (obj, um) {
                    var _a;
                    return (__assign(__assign({}, obj), (_a = {}, _a[um.getId()] = um, _a)));
                }, {});
                var packages = plainToClass(Package, data.meta.packages);
                var packagesById = packages.reduce(function (obj, p) {
                    var _a;
                    return (__assign(__assign({}, obj), (_a = {}, _a[p.getId()] = p, _a)));
                }, {});
                var visit = plainToClass(Visit, data.visit);
                var visitDetails = plainToClass(VisitDetail, data.visit.visit_details).map(function (vd) {
                    var p = plainToClass(Package, packagesById[vd.getPackageId()]);
                    unitMeasurementsById && p.setUnitMeasurement(unitMeasurementsById[p.getUnitMeasurementId()]);
                    vd.setPackage(p);
                    return vd;
                });
                doctorsById && visit.setDoctor(doctorsById[visit.getDoctorId()]);
                visit.setPatient(data.meta.patient);
                visit.setDetails(visitDetails);
                visit.setReservationVisits(data.meta.reservation_visits);
                visit.setClinicVisit(data.meta.clinic_visit);
                var reservationPackages = plainToClass(ReservationPackage, data.meta.reservation_packages);
                reservationPackages.map(function (rp) { return rp.setPackage(packagesById[rp.getPackageId()]); });
                return { visit: visit, reservationPackages: reservationPackages };
            }
        });
    };
    ClinicVisitService.prototype.useGetClinicVisitTransactions = function (id) {
        var _this = this;
        return useQuery([queries.clinicVisits, id, 'transactions'], function () {
            return _this.get("".concat(_this.clinicVisitsRoot, "/").concat(id, "/reservations-transactions"));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(ReservationTransaction, data);
            }
        });
    };
    ClinicVisitService.prototype.useGetRequiredDataToFinish = function (id) {
        var _this = this;
        return useQuery([queries.clinicVisits, id, 'dataToFinishVisit'], function () {
            return _this.get("".concat(_this.clinicVisitsRoot, "/").concat(id, "/visit/packages/data"));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                var transformedData = data.data.map(function (packageData) { return ({
                    items: plainToClass(Item, packageData.items),
                    entityType: packageData.entity_type,
                    visitDetail: plainToClass(VisitDetail, packageData.visit_detail),
                    package: plainToClass(Package, packageData.package),
                    reservationPackages: plainToClass(ReservationPackage, packageData.reservations_packages)
                }); });
                return {
                    visit: plainToClass(Visit, data.visit),
                    data: transformedData
                };
            }
        });
    };
    ClinicVisitService.prototype.useCreateMachinePackageUsage = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var currentClinicVisitId;
        return useMutation(function (_a) {
            var reservationVisitId = _a.reservationVisitId, medicalMachineVisitDetail = _a.medicalMachineVisitDetail, successMessage = _a.successMessage;
            currentClinicVisitId = reservationVisitId;
            _this.successCreationMessage = successMessage;
            var data = classToPlain(medicalMachineVisitDetail);
            var url = _this.clinicVisitsRoot + '/' + reservationVisitId + '/add/medical-machine/usage';
            return _this.post(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.clinicVisits, currentClinicVisitId]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    ClinicVisitService.prototype.useCreatePatientImages = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var reservationVisitId = _a.reservationVisitId, image = _a.image, successMessage = _a.successMessage, patientId = _a.patientId;
            _this.patientId = patientId;
            _this.successCreationMessage = successMessage;
            var newImage = classToPlain(image);
            newImage.picture = newImage.newPicture;
            var data = { image: newImage };
            var url = _this.clinicVisitsRoot + '/' + reservationVisitId + '/add/image';
            return _this.post(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.patient, _this.patientId, 'images']);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    ClinicVisitService.prototype.useCreateVisit = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var visit = _a.visit, successMessage = _a.successMessage, reservationPackages = _a.reservationPackages;
            _this.successCreationMessage = successMessage;
            var newVisit = classToPlain(visit);
            var selectedReservationPackages = classToPlain(reservationPackages);
            newVisit['reservations_packages'] = selectedReservationPackages;
            newVisit['patient'] = { id: visit.getPatientId() };
            var data = { visit: newVisit };
            return _this.post(_this.clinicVisitsRoot, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.patient, queries.packages]);
                queryClient.invalidateQueries([queries.patient, queries.visits]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    ClinicVisitService.prototype.useAddPackageToVisit = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var id;
        return useMutation(function (_a) {
            var reservationVisitId = _a.reservationVisitId, successMessage = _a.successMessage, reservationPackages = _a.reservationPackages;
            id = reservationVisitId;
            _this.successCreationMessage = successMessage;
            var data = { reservations_packages: classToPlain(reservationPackages) };
            var url = _this.clinicVisitsRoot + '/' + reservationVisitId + '/add/packages';
            return _this.post(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.clinicVisits, id]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    ClinicVisitService.prototype.useUnassignPackageToVisit = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var reservationVisitId = _a.reservationVisitId, successMessage = _a.successMessage, visitDetailId = _a.visitDetailId;
            _this.successCreationMessage = successMessage;
            var data = { visit_detail_id: visitDetailId };
            var url = _this.clinicVisitsRoot + '/' + reservationVisitId + '/remove/package';
            return _this.delete(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.patient, queries.packages]);
                queryClient.invalidateQueries([queries.patient, queries.visits]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    ClinicVisitService.prototype.useCreateTransactions = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var reservationVisitId = _a.reservationVisitId, successMessage = _a.successMessage, reservationTransactions = _a.reservationTransactions;
            _this.successCreationMessage = successMessage;
            var data = { reservation_transactions: classToPlain(reservationTransactions) };
            var url = _this.clinicVisitsRoot + '/' + reservationVisitId + '/transactions';
            return _this.post(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.clinicVisits]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    ClinicVisitService.prototype.useSettlePackage = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var id;
        return useMutation(function (_a) {
            var reservationVisitId = _a.reservationVisitId, successMessage = _a.successMessage, settlement = _a.settlement;
            id = reservationVisitId;
            _this.successCreationMessage = successMessage;
            var data = { settlement: classToPlain(settlement) };
            var url = _this.clinicVisitsRoot + '/' + reservationVisitId + '/add/settlement';
            return _this.post(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.clinicVisits, id]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    ClinicVisitService.prototype.useChangeVisitStatus = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var reservationVisitId = _a.reservationVisitId, successMessage = _a.successMessage, statusId = _a.statusId;
            _this.successCreationMessage = successMessage;
            var statusRoute = _this.routes[statusId];
            var url = _this.clinicVisitsRoot + '/' + reservationVisitId + '/set/visit/' + statusRoute;
            return _this.put(url);
        }, {
            onSuccess: function (_a) {
                var data = _a.data;
                var visit = plainToClass(Visit, data);
                queryClient.invalidateQueries([visit.getDoctorId()]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    ClinicVisitService.prototype.useFinishVisit = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var currentClinicVisitId;
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var reservationVisitId = _a.reservationVisitId, successMessage = _a.successMessage, details = _a.details;
            _this.successCreationMessage = successMessage;
            currentClinicVisitId = reservationVisitId;
            var plainedDetails = instanceToPlain(details);
            var data = { details: plainedDetails };
            var url = _this.clinicVisitsRoot + '/' + reservationVisitId + '/finish';
            return _this.post(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.clinicVisits, currentClinicVisitId, 'dataToFinishVisit']);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                navigate('/');
            }
        });
    };
    ClinicVisitService.getInstance = function () {
        if (!ClinicVisitService.clinicVisitInstance) {
            ClinicVisitService.clinicVisitInstance = new ClinicVisitService();
        }
        return ClinicVisitService.clinicVisitInstance;
    };
    return ClinicVisitService;
}(ApiService));
export default ClinicVisitService;
