import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PatientLogHeader from './PatientLogHeader';
import Article from 'library/components/Article';
import 'components/patients/style.css';
import { useState } from 'react';
import NewDiagnose from 'components/forms/Diagnoses/NewDiagnose';
import localeTranslator from 'library/Hocs/LocalTranslator';
var Diagnoses = function (props) {
    var _a = useState(false), showDiagnoseForm = _a[0], setShowDiagnoseForm = _a[1];
    var renderDiagnoses = function () { return props.diagnoses.map(function (diagnose, index) {
        return (_jsxs("div", { children: [_jsx(Article, { title: "".concat(props.translate('diagnosis'), " ").concat(diagnose.getId()), description: diagnose.getDetails().getDiagnoseText() }), index + 1 < props.diagnoses.length && _jsx("div", { className: "prescription-line" })] }, "diagnose-".concat(diagnose.getId())));
    }); };
    var goBack = function () { return props.setShowDiagnoses(false); };
    var addDiagnose = function () { return setShowDiagnoseForm(true); };
    return (_jsxs(_Fragment, { children: [_jsx(PatientLogHeader, { title: props.translate('diagnoses.title'), hasAddButton: true, hasBackButton: true, onGoBack: goBack, onAdd: addDiagnose, arrowIcon: props.arrowIcon }), showDiagnoseForm &&
                _jsx(NewDiagnose, { createDiagnose: props.createDiagnose, setShowDiagnoseForm: setShowDiagnoseForm }), _jsx("div", { className: "log", children: renderDiagnoses() })] }));
};
export default localeTranslator(Diagnoses, 'patientLog');
