import { getDiffFromNow, formatDate } from 'library/utilities/DateTime';
var Model = /** @class */ (function () {
    function Model() {
    }
    Model.prototype.adjustName = function (name) {
        if (typeof name === 'string') {
            return this.capitalizeWords(this.escapeBackSlashString(name));
        }
        return '';
    };
    Model.prototype.computeAge = function (date) {
        return getDiffFromNow(date);
    };
    Model.prototype.dateForamtter = function (date, formatter) {
        return formatDate(date, formatter);
    };
    // private methods
    Model.prototype.capitalizeWords = function (text) {
        return text.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase();
        });
    };
    Model.prototype.escapeBackSlashString = function (text) {
        if (typeof text === 'string')
            return text.replace(/\//g, ' ');
        return '';
    };
    Model.prototype.isDateValid = function (date) {
        if (Object.prototype.toString.call(date) === '[object Date]') {
            return !isNaN(date.getTime());
        }
        return false;
    };
    Model.prototype.roundNumber = function (number, digitsCount) {
        if (digitsCount === void 0) { digitsCount = 2; }
        return Number(Number(number).toFixed(digitsCount));
    };
    return Model;
}());
export default Model;
