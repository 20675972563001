import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs } from 'antd';
import PatientReservationPackages from './PatientReservationPackages';
import PatientAppointments from './PatientAppointments';
import '../../style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
import PatientTransactions from './PatientTransactions';
import services from 'services';
import { Appointments, ReservationPackage } from 'constants/resources';
var TabPane = Tabs.TabPane;
var PatientTabs = function (props) {
    var permissionService = services.permissionService;
    var flexGrowValue = function () {
        var numberOfTabs = 1;
        if (permissionService.hasPermissionToIndex(ReservationPackage)) {
            numberOfTabs++;
        }
        if (permissionService.hasPermissionToIndex(Appointments)) {
            numberOfTabs++;
        }
        return Number((1 / numberOfTabs).toFixed(2));
    };
    return (_jsx("div", { children: _jsxs(Tabs, { defaultActiveKey: '1', id: 'patient-tabs', children: [permissionService.hasPermissionToIndex(ReservationPackage) &&
                    _jsx(TabPane, { className: 'Header-16-M', tab: props.translate('tab.patientPackages'), style: { '--tab-flex-grow': flexGrowValue() }, children: _jsx(PatientReservationPackages, { patient: props.patient }) }, 'tab-1'), _jsx(TabPane, { className: 'Header-16-M', tab: props.translate('tab.patientFinancials'), style: { '--tab-flex-grow': flexGrowValue() }, children: _jsx(PatientTransactions, { patient: props.patient }) }, 'tab-2'), permissionService.hasPermissionToIndex(Appointments) &&
                    _jsx(TabPane, { className: 'Header-16-M', tab: props.translate('tab.patientVisits'), style: { '--tab-flex-grow': flexGrowValue() }, children: _jsx(PatientAppointments, { patient: props.patient }) }, 'tab-3')] }) }));
};
export default localeTranslator(PatientTabs, 'showPatient');
