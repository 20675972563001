var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var PackageReportsPerMonth = /** @class */ (function (_super) {
    __extends(PackageReportsPerMonth, _super);
    function PackageReportsPerMonth() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PackageReportsPerMonth.prototype.getReportDate = function () {
        var options = { year: 'numeric', month: 'short' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(this.report_date));
    };
    PackageReportsPerMonth.prototype.getPatientsCount = function () {
        return Number(this.patients_count);
    };
    PackageReportsPerMonth.prototype.getTotalAppointment = function () {
        return Number(this.appointments_count);
    };
    PackageReportsPerMonth.prototype.getTotalTransactions = function () {
        return this.roundNumber(this.total_transactions);
    };
    PackageReportsPerMonth.prototype.getTotalPackageReservations = function () {
        return Number(this.package_reservations_count);
    };
    PackageReportsPerMonth.prototype.getTotalPackages = function () {
        return Number(this.packages_count);
    };
    PackageReportsPerMonth.prototype.getTotalPackagePrices = function () {
        return Number(this.packages_prices_count);
    };
    return PackageReportsPerMonth;
}(Model));
export default PackageReportsPerMonth;
