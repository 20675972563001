import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import { Supplier } from 'models';
import SupplierForm from 'components/forms/inventory/suppliers/SupplierForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
var NewSupplier = function (props) {
    var supplier = useState(new Supplier())[0];
    var supplierService = services.supplierService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = supplierService.useCreateSupplier(), createSupplier = _b.mutate, errorResponse = _b.error;
    var supplierTypes = supplierService.useSupplierTypes().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        createSupplier({ supplier: supplier, successMessage: props.translate('supplierCreated') });
    };
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    var scrollToFirstElement = function () { return form.scrollToField('name', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: [supplierTypes &&
                        _jsx(SupplierForm, { supplier: supplier, supplierTypes: supplierTypes }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] })] }));
};
export default localeTranslator(NewSupplier, 'supplierForm');
