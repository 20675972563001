var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { classToPlain, instanceToPlain, plainToClass, plainToInstance } from 'class-transformer';
import packageEntites from 'constants/packageEntites';
import queries from 'constants/queries';
import { Package, PackageEntity, PackagePrice, PaginationMetaData } from 'models';
import { useMutation } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import ApiService from './ApiService';
import ItemCategoryService from './ItemCategoryService';
import MedicalMachineService from './MedicalMachineService';
import services from 'services';
var PackageService = /** @class */ (function (_super) {
    __extends(PackageService, _super);
    function PackageService() {
        var _this = _super.call(this) || this;
        _this.packagesRoot = 'packages';
        _this.successCreationMessage = '';
        _this.medicalMachineService = MedicalMachineService.getInstance();
        _this.itemCategoryService = ItemCategoryService.getInstance();
        return _this;
    }
    PackageService.prototype.usePackages = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        var currentStaleTime = Object.keys(classToPlain(filters)).length ? 0 : Infinity;
        return useQuery([queries.packages, pageNumber, filters], function () {
            return _this.get(_this.packagesRoot, __assign(__assign({}, classToPlain(filters)), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            retry: false,
            staleTime: currentStaleTime,
            select: function (_a) {
                var data = _a.data;
                var packages = plainToClass(Package, data.packages);
                return {
                    packages: packages,
                    meta: plainToClass(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta)
                };
            }
        });
    };
    PackageService.prototype.useGetPackage = function (id) {
        var _this = this;
        return useQuery([queries.patient, id], function () {
            return _this.get("".concat(_this.packagesRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(Package, data);
            }
        });
    };
    PackageService.prototype.useCreatePackage = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        var packagePriceService = services.packagePriceService;
        var createPackagePrice = packagePriceService.useCreatePackagePrice().mutate;
        var packagePrice = new PackagePrice();
        var packagePriceSuccess = '';
        return useMutation(function (_a) {
            var newPackage = _a.newPackage, newPackagePrice = _a.newPackagePrice, successMessage = _a.successMessage, packagePriceSuccessMessage = _a.packagePriceSuccessMessage;
            _this.successCreationMessage = successMessage;
            packagePriceSuccess = packagePriceSuccessMessage || '';
            packagePrice = newPackagePrice;
            var data = { package: instanceToPlain(newPackage) };
            return _this.post(_this.packagesRoot, { data: data });
        }, {
            onSuccess: function (_a) {
                var data = _a.data;
                var createdPackage = plainToClass(Package, data);
                queryClient.setQueryData([queries.packages, createdPackage.getId()], function () { return createdPackage; });
                queryClient.invalidateQueries([queries.packages]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                if (packagePrice.getQuantity()) {
                    packagePrice.setPackageId(createdPackage.getId());
                    createPackagePrice({ packagePrice: packagePrice, successMessage: packagePriceSuccess });
                }
                navigate('/packages');
            }
        });
    };
    PackageService.prototype.usePackageEntities = function () {
        var _a;
        return _a = {},
            _a["".concat(packageEntites.medicalMachine)] = this.medicalMachineService.useMedicalMachinesById(),
            _a["".concat(packageEntites.itemCategory)] = this.itemCategoryService.useItemCategoriesById(),
            _a;
    };
    PackageService.prototype.useGetPackageEntities = function () {
        var _this = this;
        return useQuery([queries.packages, 'entities'], function () {
            return _this.get(_this.packagesRoot + '/entities');
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(PackageEntity, data);
            }
        });
    };
    PackageService.getInstance = function () {
        if (!PackageService.packageService) {
            PackageService.packageService = new PackageService();
        }
        return PackageService.packageService;
    };
    return PackageService;
}(ApiService));
export default PackageService;
