import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, Layout, Col, Form, Button, Space } from 'antd';
var Header = Layout.Header;
import './style.css';
var DropDownMenu = function (props) {
    var showLoginModal = function () { return props.showLoginModal(true); };
    var goToSection = function (section) { return function () { return props.setPageSection(section); }; };
    // const changeToFeaturesSection = () => props.setPageSection('features');
    return (_jsx("div", { className: 'Transparent-Menu', children: _jsx(Header, { children: _jsx("div", { className: 'Transparent-Menu-Item', children: _jsxs(Menu, { mode: "horizontal", children: [_jsx(Col, { xl: 8, children: _jsx(Form.Item, { children: _jsx("div", { className: "body---14pt-R", children: _jsx(Menu.Item, { onClick: goToSection('company'), children: "Company" }, "company-header") }) }) }), _jsx(Col, { xl: 8, children: _jsx(Form.Item, { children: _jsx("div", { className: "body---14pt-R", children: _jsx(Menu.Item, { onClick: goToSection('features'), children: "Features" }, "features-header") }) }) }), _jsx(Col, { xl: 8, children: _jsx(Form.Item, { children: _jsx("div", { className: "body---14pt-R", children: _jsx(Menu.Item, { onClick: goToSection('pricing'), children: "Pricing" }, "pricing-header") }) }) }), _jsxs(Space, { size: 1, children: [_jsx(Col, { children: _jsx(Form.Item, { children: _jsx(Menu.Item, { onClick: goToSection('start'), children: _jsx(Button, { className: "normal-secondary-button Header-16-M", children: "Get Started" }) }, "start") }) }), _jsx(Col, { children: _jsx(Form.Item, { children: _jsx(Menu.Item, { children: _jsx(Button, { className: "normal-button Header-16-M", onClick: showLoginModal, children: "Login" }) }, "login") }) })] })] }) }) }) }));
};
export default DropDownMenu;
