var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import TopTenPatientReport from './TopTenPatientReport';
import { plainToInstance } from 'class-transformer';
import NearestTenPatientBirthdays from './NearestTenPatientBirthdays';
var PatientReport = /** @class */ (function (_super) {
    __extends(PatientReport, _super);
    function PatientReport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PatientReport.prototype.getTotalPatients = function () {
        return Number(this.total_patients);
    };
    PatientReport.prototype.getTotalPackageReservations = function () {
        return Number(this.total_package_reservations);
    };
    PatientReport.prototype.getTotalAppointment = function () {
        return Number(this.total_appointments);
    };
    PatientReport.prototype.getTotalTransactions = function () {
        return this.roundNumber(this.total_transactions);
    };
    PatientReport.prototype.getAvgTransactions = function () {
        return this.roundNumber(this.avg_transactions);
    };
    PatientReport.prototype.getTopTenPatientReport = function () {
        return plainToInstance(TopTenPatientReport, this.top_ten_patients);
    };
    PatientReport.prototype.getNearestTenBirthdays = function () {
        return plainToInstance(NearestTenPatientBirthdays, this.nearest_ten_birthdays);
    };
    return PatientReport;
}(Model));
export default PatientReport;
