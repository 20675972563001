var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { instanceToPlain, plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { Employee } from 'models';
import { useMutation } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import ApiService from './ApiService';
var EmployeeService = /** @class */ (function (_super) {
    __extends(EmployeeService, _super);
    function EmployeeService() {
        var _this = _super.call(this) || this;
        _this.employeesRoot = 'employees';
        _this.successCreationMessage = '';
        return _this;
    }
    EmployeeService.prototype.useEmployees = function () {
        var _this = this;
        return useQuery(queries.employees, function () {
            return _this.get(_this.employeesRoot);
        }, {
            select: function (response) {
                var data = response.data;
                return plainToClass(Employee, data);
            }
        });
    };
    EmployeeService.prototype.useCreateEmployee = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var employee = _a.employee, successMessage = _a.successMessage;
            _this.successCreationMessage = successMessage;
            var data = { employee: instanceToPlain(employee) };
            return _this.post(_this.employeesRoot, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries(queries.employees);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                navigate('/employees');
            }
        });
    };
    EmployeeService.prototype.useGetEmployee = function (id) {
        var _this = this;
        return useQuery([queries.employee, id], function () {
            return _this.get("".concat(_this.employeesRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(Employee, data);
            }
        });
    };
    EmployeeService.getInstance = function () {
        if (!EmployeeService.employeeInstance) {
            EmployeeService.employeeInstance = new EmployeeService();
        }
        return EmployeeService.employeeInstance;
    };
    return EmployeeService;
}(ApiService));
export default EmployeeService;
