var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { plainToInstance } from 'class-transformer';
import PackageReportsPerMonth from './PackageReportsPerMonth';
import { PackageReportsPerPackage } from '..';
var PackageReport = /** @class */ (function (_super) {
    __extends(PackageReport, _super);
    function PackageReport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PackageReport.prototype.getTotalPatients = function () {
        return Number(this.total_patients);
    };
    PackageReport.prototype.getTotalPackages = function () {
        return Number(this.total_packages);
    };
    PackageReport.prototype.getTotalPackagePrices = function () {
        return Number(this.total_package_prices);
    };
    PackageReport.prototype.getTotalPackagreservations = function () {
        return Number(this.total_package_reservations);
    };
    PackageReport.prototype.getTotalAppointment = function () {
        return Number(this.total_appointments);
    };
    PackageReport.prototype.getTotalTransactions = function () {
        return this.roundNumber(this.total_transactions);
    };
    PackageReport.prototype.getAvgTransactions = function () {
        return this.roundNumber(this.avg_transactions);
    };
    PackageReport.prototype.getReportsPerMonth = function () {
        return plainToInstance(PackageReportsPerMonth, this.reports_per_month);
    };
    PackageReport.prototype.getReportsPerPackage = function () {
        return plainToInstance(PackageReportsPerPackage, this.reports_per_package);
    };
    return PackageReport;
}(Model));
export default PackageReport;
