import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import services from 'services';
import { useParams } from 'react-router';
import AppointmentInfo from './components/informations/AppointmentInfo';
import PatientInfo from './components/informations/PatientInfo';
import { Button, Space } from 'antd';
import PackagesInfo from './components/informations/PackagesInfo';
import Header from './components/Header';
import { useState } from 'react';
import PackagesDrawer from './components/drawers/PackagesDrawer';
import Loader from 'components/AntEnhancement/Loader';
import localeTranslator from 'library/Hocs/LocalTranslator';
import PatientLogDrawer from './components/drawers/PatientLogDrawer';
var ShowAppointment = function (props) {
    var id = useParams().id;
    var userService = services.userService, appointmentService = services.appointmentService, permissionService = services.permissionService;
    var _a = appointmentService.useGetAppointment(Number(id)), appointment = _a.data, isAppointmentLoading = _a.isLoading;
    var _b = useState(0), packagesDrawerKey = _b[0], setPackagesDrawerKey = _b[1];
    var _c = useState(false), showPackagesDrawer = _c[0], setShowPackagesDrawer = _c[1];
    var onClosePackagesDrawer = function () {
        setShowPackagesDrawer(false);
        setPackagesDrawerKey(packagesDrawerKey + 1);
    };
    var _d = useState(0), patientLogDrawerKey = _d[0], setPatientLogDrawerKey = _d[1];
    var _e = useState(false), showPatientLogDrawer = _e[0], setShowPatientLogDrawer = _e[1];
    var onClosePatientLogDrawer = function () {
        setShowPatientLogDrawer(false);
        setPatientLogDrawerKey(patientLogDrawerKey + 1);
    };
    var openPatientLogDrawer = function () { return setShowPatientLogDrawer(true); };
    var loginData = userService.useLoginData().data;
    var getPatientLogButtonDirectionClass = function () { return (loginData === null || loginData === void 0 ? void 0 : loginData.lang) && (loginData === null || loginData === void 0 ? void 0 : loginData.lang) != 'en' ?
        'patient-log-button-rtl' : 'patient-log-button-ltr'; };
    return (_jsx(Loader, { renderSpinner: isAppointmentLoading, children: _jsxs(_Fragment, { children: [_jsx("div", { className: "visit-header", children: appointment && _jsx(Header, { appointment: appointment }) }), appointment && loginData && permissionService.hasPatientLogPermission() &&
                    _jsx(Button, { type: "default", className: "patient-log-button ".concat(getPatientLogButtonDirectionClass()), onClick: openPatientLogDrawer, children: props.translate('patientLogButton') }), _jsx("div", { className: "visit-page-content", children: _jsx("div", { className: "visit-content", children: _jsxs(Space, { direction: "vertical", size: 10, children: [appointment && _jsx(AppointmentInfo, { appointment: appointment }), appointment && _jsx(PatientInfo, { appointment: appointment }), appointment &&
                                    _jsx(PackagesInfo, { appointment: appointment, reservationPackages: [], reservationsTransactions: [], setShowPackagesDrawer: setShowPackagesDrawer })] }) }) }), appointment &&
                    _jsx(PackagesDrawer, { visiable: showPackagesDrawer, appointment: appointment, onCloseDrawer: onClosePackagesDrawer }, 'Packages-' + packagesDrawerKey), appointment && permissionService.hasPatientLogPermission() &&
                    _jsx(PatientLogDrawer, { visiable: showPatientLogDrawer, onCloseDrawer: onClosePatientLogDrawer, appointment: appointment }, 'patientLog-' + patientLogDrawerKey)] }) }));
};
export default localeTranslator(ShowAppointment, 'showVisit');
