import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Divider, Space } from 'antd';
import Header from 'pages/ShowReservationPackage/components/Header';
import Loader from 'components/AntEnhancement/Loader';
import AppointmentDetails from 'pages/ShowReservationPackage/components/Tables/AppointmentDetails';
import OtherInfo from 'pages/ShowReservationPackage/components/Informations/OtherInfo';
import MainInfo from 'pages/ShowReservationPackage/components/Informations/MainInfo';
import PatientInfo from 'pages/ShowReservationPackage/components/Informations/PatientInfo';
import PackagePriceInfo from 'pages/ShowReservationPackage/components/Informations/PackagePriceInfo';
import { useParams } from 'react-router';
import services from 'services';
import './style.css';
var ShowReservationPackage = function () {
    var id = useParams().id;
    var reservationPackageService = services.reservationPackageService;
    var _a = reservationPackageService.useGetReservationPackage(Number(id)), currentReservationPackage = _a.data, isReservationPackageLoading = _a.isLoading;
    return (_jsx("div", { className: "loader-container-height", children: _jsx(Loader, { renderSpinner: isReservationPackageLoading, children: _jsxs("div", { children: [_jsx("div", { className: "page-heade", children: _jsx(Header, {}) }), _jsx("div", { className: "index-page-content", children: _jsxs(Space, { direction: "vertical", size: 24, children: [_jsxs(Row, { children: [_jsx(Col, { xl: 12, children: currentReservationPackage && _jsx(MainInfo, { currentReservationPackage: currentReservationPackage }) }), _jsx(Col, { sm: 1 }), _jsx(Col, { xl: 11, children: currentReservationPackage && _jsx(OtherInfo, { currentReservationPackage: currentReservationPackage }) })] }), currentReservationPackage && _jsx(PatientInfo, { currentReservationPackage: currentReservationPackage }), currentReservationPackage && _jsx(PackagePriceInfo, { currentReservationPackage: currentReservationPackage })] }) }), _jsx(Divider, {}), currentReservationPackage && _jsx(AppointmentDetails, { currentReservationPackage: currentReservationPackage })] }) }) }));
};
export default ShowReservationPackage;
