var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { useQuery } from 'react-query';
import queries from 'constants/queries';
import ApiService from './ApiService';
import { Clinic } from 'models';
import { plainToInstance } from 'class-transformer';
var ClinicService = /** @class */ (function (_super) {
    __extends(ClinicService, _super);
    function ClinicService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rootPath = 'clinics';
        _this.clinicInfoPath = 'clinic/info';
        return _this;
    }
    ClinicService.prototype.useClinicInfo = function () {
        var _this = this;
        return useQuery(queries.clinic, function () {
            return _this.get(_this.clinicInfoPath);
        }, {
            select: function (response) {
                var data = response.data;
                return plainToInstance(Clinic, data);
            }
        });
    };
    ClinicService.prototype.useClinics = function () {
        var _this = this;
        return useQuery(queries.clinics, function () { return _this.get(_this.rootPath); }, {
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(Clinic, data);
            }
        });
    };
    ClinicService.prototype.getClinicByCode = function (clinics, clinicCode) {
        return clinics.find(function (clinic) { return clinic.getCode() == clinicCode; });
    };
    ClinicService.getInstance = function () {
        if (!ClinicService.clinicInstance) {
            ClinicService.clinicInstance = new ClinicService();
        }
        return ClinicService.clinicInstance;
    };
    return ClinicService;
}(ApiService));
export default ClinicService;
