import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import Loader from 'components/AntEnhancement/Loader';
import { FinancialPeriod as FinancialPeriodResource } from 'constants/resources';
import NormalHeader from 'components/PageHeader/NormalHeader';
var FinancialPeriods = function (props) {
    var financialPeriodService = services.financialPeriodService;
    var _a = financialPeriodService.useFinancialPeriods(), financialPeriods = _a.data, isLoading = _a.isLoading;
    var openNewFinancialPeriod = financialPeriodService.useOpenFinancialPeriod().mutate;
    var closeOpeningFinancialPeriod = financialPeriodService.useCloseFinancialPeriod().mutate;
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('fromDate') }),
            key: 'fromDate',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getFormattedFromDate() }, 'fromDate-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('toDate') }),
            key: 'toDate',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getFormattedToDate() }, 'toDate-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('active') }),
            key: 'active',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getIsOpen() ? _jsx(CheckOutlined, {}) : _jsx(CloseOutlined, {}) }, 'active-' + index);
            }
        }
    ];
    var closeFinancialPeriod = function (id) { return function () {
        closeOpeningFinancialPeriod({ id: id, successMessage: props.translate('messages.financialPeriodClosed') });
    }; };
    var openFinancialPeriod = function () {
        openNewFinancialPeriod({ successMessage: props.translate('messages.financialPeriodOpened') });
    };
    var buttonData = function () {
        var openedFinancialPeriod = financialPeriods === null || financialPeriods === void 0 ? void 0 : financialPeriods.find(function (fp) { return fp.getIsOpen(); });
        return {
            buttonText: openedFinancialPeriod ? 'Close Financial Period' : 'Open Financial Period',
            buttonFun: openedFinancialPeriod ? closeFinancialPeriod(openedFinancialPeriod.getId()) : openFinancialPeriod
        };
    };
    return (_jsx(Loader, { renderSpinner: isLoading, children: _jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header.financialPeriods'), addButtonFunction: buttonData().buttonFun, addButtonText: buttonData().buttonText, resource: FinancialPeriodResource }), _jsx("div", { className: "page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: false, dataSource: financialPeriods, locale: {
                            emptyText: _jsx(EmptyComponent, {})
                        } }) })] }) }));
};
export default localeTranslator(FinancialPeriods, 'financialPeriods');
