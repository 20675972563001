var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { instanceToPlain, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { PaginationMetaData, Transaction } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from '../ApiService';
import { useNavigate } from 'react-router';
var TransactionSevice = /** @class */ (function (_super) {
    __extends(TransactionSevice, _super);
    function TransactionSevice() {
        var _this = _super.call(this) || this;
        _this.transactionsRoot = 'transactions';
        _this.successMessage = '';
        return _this;
    }
    TransactionSevice.prototype.useTransactions = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.accounting.transactions, pageNumber, filters], function () {
            return _this.get(_this.transactionsRoot, __assign(__assign({}, filters), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                var transactions = plainToInstance(Transaction, data === null || data === void 0 ? void 0 : data.transactions);
                var meta = plainToInstance(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta);
                return { transactions: transactions, meta: meta };
            }
        });
    };
    TransactionSevice.prototype.useGetTransaction = function (id) {
        var _this = this;
        return useQuery(['show', queries.accounting.transactions, id], function () {
            return _this.get("".concat(_this.transactionsRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(Transaction, data);
            }
        });
    };
    TransactionSevice.prototype.useCreateTransaction = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var transaction = _a.transaction, successMessage = _a.successMessage;
            _this.successMessage = successMessage;
            var data = { transaction: instanceToPlain(transaction) };
            return _this.post(_this.transactionsRoot, { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(queries.accounting.transactions);
                _this.dipatachCreationSuccess({ successMessage: _this.successMessage });
                navigate('/transactions');
            }
        });
    };
    TransactionSevice.prototype.useCreatePatientTransaction = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var transaction = _a.transaction, successMessage = _a.successMessage;
            _this.successMessage = successMessage;
            var data = { transaction: instanceToPlain(transaction) };
            return _this.post("".concat(_this.transactionsRoot, "/create_patient_transaction"), { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(queries.accounting.transactions);
                _this.dipatachCreationSuccess({ successMessage: _this.successMessage });
            }
        });
    };
    TransactionSevice.prototype.useRevertTransaction = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        var currentTransaction = null;
        return useMutation(function (_a) {
            var transaction = _a.transaction, successMessage = _a.successMessage;
            _this.successMessage = successMessage;
            currentTransaction = transaction;
            return _this.put("".concat(_this.transactionsRoot, "/").concat(transaction.getId(), "/revert"));
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(['show', queries.accounting.transactions, currentTransaction === null || currentTransaction === void 0 ? void 0 : currentTransaction.getId()]);
                _this.dipatachCreationSuccess({ successMessage: _this.successMessage });
                navigate("/transactions/".concat(currentTransaction === null || currentTransaction === void 0 ? void 0 : currentTransaction.getId()));
            }
        });
    };
    TransactionSevice.prototype.useRefundTransaction = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        var currentTransaction = null;
        return useMutation(function (_a) {
            var transaction = _a.transaction, successMessage = _a.successMessage;
            _this.successMessage = successMessage;
            currentTransaction = transaction;
            return _this.put("".concat(_this.transactionsRoot, "/").concat(transaction.getId(), "/refund"));
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(['show', queries.accounting.transactions, currentTransaction === null || currentTransaction === void 0 ? void 0 : currentTransaction.getId()]);
                _this.dipatachCreationSuccess({ successMessage: _this.successMessage });
                navigate("/transactions/".concat(currentTransaction === null || currentTransaction === void 0 ? void 0 : currentTransaction.getId()));
            }
        });
    };
    TransactionSevice.getInstance = function () {
        if (!TransactionSevice.transactionSevice) {
            TransactionSevice.transactionSevice = new TransactionSevice();
        }
        return TransactionSevice.transactionSevice;
    };
    return TransactionSevice;
}(ApiService));
export default TransactionSevice;
