import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Route, Routes } from 'react-router-dom';
import MedicalMachineRoutes from './medicalMachines';
import PackageRoutes from './packages';
import EmployeeRoutes from './employees';
import DoctorRoutes from './doctors';
import SettingsRoutes from './settings';
import PatientRoutes from './patients';
import ReservationPackageRoutes from './reservationPackages';
import AppointmentRoutes from './appointments';
import Landing from 'pages/Landing';
import HomeRoutes from './Home';
import AccountRoutes from './accounts';
import AccountTypesRoutes from './accountTypes';
import BalancesRoutes from './balances';
import DocumentRoutes from './documents';
import FinancialPeriodsRoutes from './financialPeriods';
import TransactionsRoutes from './transactions';
import ProductCategoriesRoutes from './productCategories';
import ProductsRoutes from './products';
import SuppliersRoutes from './suppliers';
import StocksRoutes from './stocks';
import StockMovementsRoutes from './stockMovements';
import ReportsRoutes from './reports';
export var LoggedInRoutes = function () {
    return (_jsxs(Routes, { children: [HomeRoutes(), PatientRoutes(), DoctorRoutes(), MedicalMachineRoutes(), ReservationPackageRoutes(), PackageRoutes(), EmployeeRoutes(), SettingsRoutes(), AppointmentRoutes(), AccountTypesRoutes(), AccountRoutes(), BalancesRoutes(), FinancialPeriodsRoutes(), DocumentRoutes(), TransactionsRoutes(), ProductCategoriesRoutes(), ProductsRoutes(), SuppliersRoutes(), StocksRoutes(), StockMovementsRoutes(), ReportsRoutes()] }));
};
export var AuthRoutes = function () {
    return (_jsx(Routes, { children: _jsx(Route, { path: '/', element: _jsx(Landing, {}) }) }));
};
export default {
    AuthRoutes: AuthRoutes,
    LoggedInRoutes: LoggedInRoutes
};
