import { jsx as _jsx } from "react/jsx-runtime";
import { Table, Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import EmptyComponent from 'components/EmptyComponent';
import services from 'services';
import Loader from 'components/AntEnhancement/Loader';
import { useNavigate } from 'react-router-dom';
import '../../style.css';
var PatientReservationPackages = function (props) {
    var navigate = useNavigate();
    var patientService = services.patientService;
    var _a = patientService.useGetPatientReservationPackages(props.patient.getId()), reservationPackages = _a.data, isLoadingPatientPackages = _a.isLoading;
    var goToReservationPackage = function (reservationPackageId) { return function () { return navigate("/reservation-packages/".concat(reservationPackageId)); }; };
    var menu = function (reservationPackageId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToReservationPackage(reservationPackageId), children: props.translate('common:buttons.show') }, "1") }));
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('packageName') }),
            width: '14%',
            key: 'packageName',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getPackagePrice().getPackageName() }, 'packageName-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('status') }),
            width: '14%',
            key: 'status',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getDisplayedStatus() }, 'status-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('price') }),
            key: 'price',
            render: function (text, record, index) {
                return _jsx("ul", { className: "body---14pt-R table-body-content", children: record.getPackagePrice().getTotalPrice() * record.getRepeated() }, 'price-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('remainder') }),
            key: 'remainder',
            render: function (text, record, index) {
                return _jsx("ul", { className: "body---14pt-R table-body-content", children: record.getRemainder() }, 'remainder-' + index);
            }
        },
        {
            key: 'action',
            render: function (text, record, index) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" }, "action-".concat(index));
            }
        }
    ];
    return (_jsx(Loader, { renderSpinner: isLoadingPatientPackages, className: 'no-class', children: _jsx(Table, { columns: columns, pagination: {
                position: ['bottomRight'],
                pageSize: 4,
                responsive: true
            }, dataSource: reservationPackages, locale: {
                emptyText: _jsx(EmptyComponent, {})
            } }) }));
};
export default localeTranslator(PatientReservationPackages, 'reservationPackages');
