import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import { Transaction } from 'models';
import localeTranslator from 'library/Hocs/LocalTranslator';
import TransactionForm from 'components/forms/accounting/transactions/TransactionForm';
var NewTransaction = function (props) {
    var transaction = useState(new Transaction())[0];
    var transactionService = services.transactionService, documentLineGroupService = services.documentLineGroupService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = transactionService.useCreateTransaction(), createTransaction = _b.mutate, errorResponse = _b.isError;
    var expenseGroups = documentLineGroupService.useExpenseGroups().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        createTransaction({ transaction: transaction, successMessage: props.translate('transactionCreated') });
    };
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    var scrollToFirstElement = function () { return form.scrollToField('name', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: [expenseGroups && _jsx(TransactionForm, { documentLineGroups: expenseGroups, transaction: transaction }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] })] }));
    // disabled={disabled}
};
export default localeTranslator(NewTransaction, 'transactionForm');
