import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Transaction as TransactionResource } from 'constants/resources';
import NormalHeader from 'components/PageHeader/NormalHeader';
var Transactions = function (props) {
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var navigate = useNavigate();
    var transactionService = services.transactionService;
    var data = transactionService.useTransactions(pageNumber).data;
    var goToTransaction = function (transactionId) { return function () { return navigate("/transactions/".concat(transactionId)); }; };
    var menu = function (transactionId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToTransaction(transactionId), children: props.translate('common:buttons.show') }, "1") }));
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getId() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('code') }),
            key: 'code',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getCode() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('amount') }),
            key: 'amount',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: props.translate('common:price', { amount: record.getAmount() }) });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('status') }),
            key: 'status',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getDisplayedStatus() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('document') }),
            key: 'document',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getDocument().getName() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('clinic') }),
            key: 'clinic',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getClinic().getName() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('transactionDate') }),
            key: 'paidAt',
            // responsive: ['lg'],
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getFormattedTransactionDate() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('action') }),
            fixed: 'right',
            render: function (text, record) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" });
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var goToNewExpenseForm = function () { return navigate('/transactions/expenses/new'); };
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header.transactions'), addButtonText: props.translate('common:buttons.newExpense'), addButtonFunction: goToNewExpenseForm, resource: TransactionResource }), _jsx("div", { className: "index-page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, dataSource: data === null || data === void 0 ? void 0 : data.transactions, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(Transactions, 'transactions');
