var queries = {
    user: 'USER',
    clinic: 'CLINIC',
    clinics: 'CLINICS',
    loginData: 'LOGIN_DATA',
    patients: 'PATIENTS',
    patient: 'PATIENT',
    transactions: 'TRANSACTIONS',
    medicalMachines: 'MEDICAL_MACHINES',
    specializations: 'SPECIALIZATIONS',
    unitMeasurement: 'UNIT_MEASUREMENT',
    itemCategories: 'ITEM_CATEGORIES',
    reservations: 'RESERVATIONS',
    reservationPackages: 'RESERVATION_PACKAGES',
    packages: 'PACKAGES',
    packagePrices: 'PACKAGE_PRICES',
    doctors: 'DOCTORS',
    doctor: 'DOCTOR',
    employees: 'EMPLOYEES',
    employee: 'EMPLOYEE',
    visits: 'VISITS',
    reservationVisits: 'RESERVATION_VISITS',
    clinicVisits: 'CLINIC_VISITS',
    home: 'HOME',
    jobTitles: 'JOB_TITLES',
    roles: 'ROLES',
    patientAreas: 'PATIENT_AREAS',
    appointments: 'APPOINTMENTS',
    homeDoctorQueue: 'HOME_DOCTOR_QUEUE',
    appointmentTypes: 'APPOINTMENT_TYPES',
    appointmentSettlements: 'APPOINTMENT_SETTLEMENTS',
    prescriptions: 'PRESCRIPTIONS',
    medicalTests: 'MEDICAL_TESTS',
    followUps: 'FOLLOW_UPS',
    diagnoses: 'DIAGNOSES',
    permissions: 'PERMISSIONS',
    languages: 'LANGUAGES',
    accounting: {
        accountTypes: 'ACCOUNT_TYPES',
        accounts: 'ACCOUNTS',
        documents: 'DOCUMENTS',
        documentLineGroups: 'DOCUMENT_LINE_GROUPS',
        documentLines: 'DOCUMENT_LINES',
        financialPeridos: 'FINANCIAL_PERIODS',
        balances: 'BALANCES',
        transactions: 'TRANSACTIONS',
        transactionEntries: 'TRANSACTION_ENTRIES',
    },
    inventory: {
        productCategories: 'PRODUCT_CATEGORIES',
        products: 'PRODUCTS',
        suppliers: 'SUPPLIERS',
        supplierBranches: 'SUPPER_BRANCHES',
        stocks: 'STOCKS',
        stockMovements: 'STOCK_MOVEMENTS',
    },
    reports: {
        clinicReports: 'CLINIC_REPORTS',
        patientReports: 'PATIENT_REPORTS',
        packageReports: 'PACKAGE_REPORTS',
        doctorReports: 'DOCTOR_REPORTS',
    }
};
export default Object.freeze(queries);
