import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Row, Col, Space, Input } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInputNumber, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
var TextArea = Input.TextArea;
var TransactionForm = function (props) {
    var onChangeDescription = function (event) {
        var _a;
        (_a = props.transaction) === null || _a === void 0 ? void 0 : _a.setNote(event.target.value);
    };
    var onChangeAmount = function (value) { var _a; return value && ((_a = props.transaction) === null || _a === void 0 ? void 0 : _a.setAmount(value)); };
    var onChangeDocumentLineGroupId = function (value) { return props.transaction.setDocumentLineGroupId(Number(value)); };
    var getDocumentLineGroups = function () {
        return props.documentLineGroups.map(function (documentLineGroup) { return ({
            value: documentLineGroup.getId(),
            title: documentLineGroup.getName()
        }); });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('basicInfo') }), _jsx("div", { className: "form-part", children: _jsxs(Space, { direction: 'vertical', className: 'space-item', size: 'large', children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormSelect, { label: props.translate('document'), name: 'dcoumentLineGroup', requiredMark: true, rules: [], placeHolder: props.translate('document'), onSelect: onChangeDocumentLineGroupId, options: getDocumentLineGroups(), initialValue: props.transaction.getDocumentLineGroupId() || null }) }) }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInputNumber, { label: props.translate('amount'), name: "amount", rules: [
                                            { required: true, message: props.translate('errors.amount') }
                                        ], placeHolder: props.translate('amount'), onChange: onChangeAmount, min: 0 }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 })] }), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(Form.Item, { label: props.translate('description'), name: "description", className: "form-item body---14pt-R", children: _jsx(TextArea, { className: "text-area-border space-item", placeholder: props.translate('description'), onChange: onChangeDescription, rows: 7 }) }) }) })] }) })] }));
};
export default localeTranslator(TransactionForm, 'transactionForm');
