import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space } from 'antd';
import PackagePriceForm from 'components/forms/packagePrices/PackagePriceForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { PackagePrice } from 'models';
import services from 'services';
import '../../style.css';
import { useParams } from 'react-router';
var AddPackagePriceDrawer = function (props) {
    var id = useParams().id;
    var packagePrice = useState(new PackagePrice())[0];
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var packagePriceService = services.packagePriceService, unitMeasurementService = services.unitMeasurementService, userService = services.userService;
    var unitMeasurements = unitMeasurementService.useUnitMeasurements().data;
    var loginData = userService.useLoginData().data;
    var _b = packagePriceService.useCreatePackagePrice(), createPackagePrice = _b.mutate, isPackagePriceCreated = _b.isSuccess, errorResponse = _b.isError;
    useEffect(function () {
        isPackagePriceCreated && onClose();
    }, [isPackagePriceCreated]);
    var onSubmit = function () {
        packagePrice.setPackageId(Number(id));
        setDisabled(true);
        createPackagePrice({ packagePrice: packagePrice, successMessage: 'packagePriceCreated' });
    };
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    var placement = (loginData === null || loginData === void 0 ? void 0 : loginData.lang) && (loginData === null || loginData === void 0 ? void 0 : loginData.lang) == 'en' ? 'left' : 'right';
    return (_jsx(Drawer, { title: props.translate('drawers.newPackagePrice'), placement: 'bottom', open: props.visiable, size: "default", onClose: onClose, height: 642, className: "transaction-drawer", children: _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: _jsxs(Space, { direction: 'vertical', size: 'large', children: [unitMeasurements &&
                        _jsx(PackagePriceForm, { packagePrice: packagePrice, unitMeasurements: unitMeasurements }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) })] }) }) }, placement));
};
export default localeTranslator(AddPackagePriceDrawer, 'showPackage');
