import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Space, Form } from 'antd';
import { Package, PackagePrice } from 'models';
import services from 'services';
import PackageForm from 'components/forms/packages/PackageForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
var NewPackage = function (props) {
    var newPackage = useState(new Package())[0];
    var newPackagePrice = useState(new PackagePrice())[0];
    var packageService = services.packageService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = packageService.useCreatePackage(), createPackage = _b.mutate, errorResponse = _b.isError;
    var packageEntities = packageService.useGetPackageEntities().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        createPackage({
            newPackage: newPackage,
            newPackagePrice: newPackagePrice,
            successMessage: props.translate('packageCreated'),
            packagePriceSuccessMessage: props.translate('packagePriceCreated')
        });
    };
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    var scrollToFirstElement = function () { return form.scrollToField('amount', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: _jsxs(Space, { direction: 'vertical', size: 60, style: { width: '100%' }, children: [packageEntities &&
                            _jsx(PackageForm, { package: newPackage, packageEntities: packageEntities, packagePrice: newPackagePrice }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] }) })] }));
};
export default localeTranslator(NewPackage, 'newPackage');
