import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Form, Button } from 'antd';
import services from 'services';
var NormalHeader = function (props) {
    var permissionService = services.permissionService;
    return (_jsx("div", { className: "page-header", children: _jsxs(Row, { gutter: { xs: 24, sm: 16, md: 24, lg: 32 }, children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 10, children: _jsx("div", { className: "Header-24-bold title", children: props.title }) }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 8 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 5, children: props.addButtonText && props.addButtonFunction &&
                        (props.resource && permissionService.hasPermissionToAdd(props.resource)) &&
                        _jsx(Form.Item, { children: _jsx(Button, { className: props.addButtonClasses || "normal-button Header-16-M", onClick: props.addButtonFunction, children: props.addButtonText }) }) })] }) }));
};
export default NormalHeader;
