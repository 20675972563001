import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Space } from 'antd';
import moment from 'moment';
import DateHeader from 'components/PageHeader/DateHeader';
import services from 'services';
import DoctorQueue from './Queue';
import DoctorPicker from './DoctorPicker';
import './style.css';
var index = function () {
    var doctorService = services.doctorService, clinicService = services.clinicService, userService = services.userService;
    var _a = doctorService.useDoctors(), data = _a.data, isSuccess = _a.isSuccess;
    var clinics = clinicService.useClinics().data;
    var loggedInData = userService.useLoginData().data;
    var _b = useState(moment()), date = _b[0], setDate = _b[1];
    var _c = useState(data), doctors = _c[0], setDoctors = _c[1];
    useEffect(function () {
        setDoctors(data);
    }, [isSuccess]);
    var showDivider = function (index) {
        var doctorsCount = (doctors === null || doctors === void 0 ? void 0 : doctors.length) || 0;
        return index < doctorsCount - 1;
    };
    return (_jsxs(Space, { direction: 'vertical', size: 150, children: [_jsx("div", { className: 'page-header remove-padding', children: clinics && loggedInData &&
                    _jsx(DateHeader, { clinic: clinicService.getClinicByCode(clinics, loggedInData === null || loggedInData === void 0 ? void 0 : loggedInData.clinicCode), date: date, setDate: setDate }) }), _jsx("div", { className: 'queue', children: _jsxs(Space, { direction: 'horizontal', size: 50, style: { alignItems: 'flex-start' }, children: [doctors === null || doctors === void 0 ? void 0 : doctors.slice(0, 3).map(function (doctor, index) {
                            var _a;
                            return _jsxs(_Fragment, { children: [_jsx(DoctorQueue, { doctor_id: doctor.getId(), doctor_name: (_a = doctor.getUser()) === null || _a === void 0 ? void 0 : _a.getName(), date: date }, "doctor-".concat(doctor.getId(), "-").concat(new Date().getTime())), showDivider(index) && _jsx("div", { style: { borderLeft: '1px solid #d1dbe6', height: '100vh' } })] });
                        }), doctors && isSuccess && doctors.length > 3 &&
                            _jsx(DoctorPicker, { doctors: doctors, setDoctors: setDoctors })] }) })] }));
};
export default index;
