import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import { Doctor, User } from 'models';
import UserMainInfo from 'components/forms/users/UserMainInfo';
import SignUpForm from 'components/forms/users/SignUpForm';
import JobInfoForm from 'components/forms/doctors/JobInfoForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
var NewDoctor = function (props) {
    var user = useState(new User())[0];
    var doctor = useState(new Doctor())[0];
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var specializationService = services.specializationService, doctorService = services.doctorService;
    var specializations = specializationService.useSpecializations().data;
    var _b = doctorService.useCreateDoctor(), createDoctor = _b.mutate, requestStatus = _b.status;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        doctor.setUser(user);
        createDoctor({ doctor: doctor, successMessage: props.translate('doctorCreated') });
    };
    useEffect(function () {
        if (requestStatus == 'error') {
            setDisabled(false);
        }
    }, [requestStatus]);
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, children: _jsxs(Space, { direction: 'vertical', size: 60, style: { width: '100%' }, children: [_jsx(UserMainInfo, { entity: doctor, user: user }), _jsx(SignUpForm, { user: user }), specializations && _jsx(JobInfoForm, { doctor: doctor, specializations: specializations }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] }) })] }));
};
export default localeTranslator(NewDoctor, 'doctorForm');
