import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Drawer, Form } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Appointment } from 'models';
import services from 'services';
import '../../style.css';
import AppointmentMetaInfoForm from 'components/forms/visits/AppointmentMetaInfoForm';
import AppointmentForm from 'components/forms/visits/AppointmentForm';
import { useParams } from 'react-router';
var AddAppointmentDrawer = function (props) {
    var appointment = useState(new Appointment())[0];
    var id = useParams().id;
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var clinicService = services.clinicService, doctorService = services.doctorService, appointmentService = services.appointmentService;
    var clinics = clinicService.useClinics().data;
    var doctors = doctorService.useDoctors().data;
    var _a = appointmentService.useCreateAppointment(), createAppointment = _a.mutate, isAppointmentCreated = _a.isSuccess;
    useEffect(function () {
        isAppointmentCreated && onClose();
    }, [isAppointmentCreated]);
    var onSubmit = function () {
        appointment.setPatientId(Number(id));
        createAppointment({ appointment: appointment });
    };
    return (_jsx(Drawer, { title: props.translate('visitDrawer.title'), placement: "right", open: props.visiable, size: "default", onClose: onClose, width: 742, className: "transaction-drawer", children: _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: [clinics && doctors &&
                    _jsx(_Fragment, { children: _jsxs("div", { className: "drawer-form-part", children: [_jsx(AppointmentMetaInfoForm, { appointment: appointment, doctors: doctors, clinics: clinics }), _jsx(AppointmentForm, { appointment: appointment, isRequireCollapse: false })] }) }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }, "right"));
};
export default localeTranslator(AddAppointmentDrawer, 'showPatient');
