import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import { Document } from 'models';
import localeTranslator from 'library/Hocs/LocalTranslator';
import DocumentForm from 'components/forms/accounting/documents/DocumentForm';
var NewDocument = function (props) {
    var document = useState(new Document())[0];
    var documentService = services.documentService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = documentService.useCreateDocument(), createDocument = _b.mutate, errorResponse = _b.isError;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        createDocument({ document: document, successMessage: props.translate('documentCreated') });
    };
    useEffect(function () {
        if (errorResponse) {
            setDisabled(false);
        }
    }, [errorResponse]);
    var scrollToFirstElement = function () { return form.scrollToField('name', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: [_jsx(DocumentForm, { document: document }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] })] }));
};
export default localeTranslator(NewDocument, 'documentForm');
