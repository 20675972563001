// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-offset {
  margin-inline-end: 40px;
}

.arrange-buttons {
  display: flex !important;
  justify-content: flex-end !important;
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/EntityHeader/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,oCAAoC;AACtC","sourcesContent":[".buttons-offset {\n  margin-inline-end: 40px;\n}\n\n.arrange-buttons {\n  display: flex !important;\n  justify-content: flex-end !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
