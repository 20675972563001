var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var FinancialPeriod = /** @class */ (function (_super) {
    __extends(FinancialPeriod, _super);
    function FinancialPeriod() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FinancialPeriod.prototype.getId = function () {
        return Number(this.id);
    };
    FinancialPeriod.prototype.getActive = function () {
        return this.active;
    };
    FinancialPeriod.prototype.setAcitve = function (active) {
        this.active = active;
    };
    FinancialPeriod.prototype.getFormattedFromDate = function () {
        return this.dateForamtter(new Date(this.from_date), 'DD/MM/YYYY hh:mm A');
    };
    FinancialPeriod.prototype.getFromDate = function () {
        return new Date(this.from_date);
    };
    FinancialPeriod.prototype.setFromDate = function (fromDate) {
        this.from_date = fromDate;
    };
    FinancialPeriod.prototype.getFormattedToDate = function () {
        return this.to_date ? this.dateForamtter(new Date(this.to_date), 'DD/MM/YYYY hh:mm A') : '-';
    };
    FinancialPeriod.prototype.getToDate = function () {
        return new Date(this.to_date);
    };
    FinancialPeriod.prototype.setToDate = function (toDate) {
        this.to_date = toDate;
    };
    FinancialPeriod.prototype.getIsOpen = function () {
        return this.is_open;
    };
    return FinancialPeriod;
}(Model));
export default FinancialPeriod;
