import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import EmptyComponent from 'components/EmptyComponent';
import services from 'services';
import Loader from 'components/AntEnhancement/Loader';
import { useNavigate } from 'react-router';
var PatientAppointments = function (props) {
    var navigate = useNavigate();
    var patientService = services.patientService;
    var _a = patientService.useGetPatientAppointments(props.patient.getId()), appointments = _a.data, isAppointmentsLoading = _a.isLoading;
    var goToAppointment = function (appointment) { return function () { return navigate("/appointments/".concat(appointment.getId())); }; };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientVisits.visitId') }),
            dataIndex: 'visitId',
            key: 'visitId',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content link", onClick: goToAppointment(record), children: record.getId() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientVisits.clinic') }),
            dataIndex: 'clinic',
            key: 'clinic',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getClinic().getName() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientVisits.time') }),
            dataIndex: 'time',
            key: 'time',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getFormattedAppointmentTime() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientVisits.status') }),
            dataIndex: 'status',
            key: 'status',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getDisplayedStatus() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('patientVisits.doctor') }),
            dataIndex: 'doctor',
            key: 'doctor',
            render: function (text, record) {
                var _a;
                return _jsx("div", { className: "body---14pt-R table-body-content", children: (_a = record.getDoctor().getUser()) === null || _a === void 0 ? void 0 : _a.getName() });
            }
        }
    ];
    return (_jsx(Loader, { renderSpinner: isAppointmentsLoading, className: 'no-class', children: _jsx(Table, { columns: columns, pagination: {
                position: ['bottomRight'],
                pageSize: 4,
                responsive: true
            }, dataSource: appointments, locale: {
                emptyText: _jsx(EmptyComponent, {})
            } }) }));
};
export default localeTranslator(PatientAppointments, 'showPatient');
