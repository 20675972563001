import { jsx as _jsx } from "react/jsx-runtime";
import { Form, Select, Collapse } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import { RightMarkCircleIcon } from 'assets/icons';
import useForceUpdate from 'library/hooks/forceUpdate';
var Option = Select.Option;
var Panel = Collapse.Panel;
var DoctorSearch = function (props) {
    var forceUpdate = useForceUpdate();
    var panelHeader = function () { return _jsx("span", { className: "Header-18-M", children: props.translate('reservationInfo.chooseDoctor') }); };
    var onSelectDoctor = function (doctorId) {
        var doctor = props.doctors.find(function (d) { return d.getId() === doctorId; });
        doctor && props.entity.setDoctor(doctor);
        forceUpdate();
    };
    var getExtraIcon = function () { return !!props.entity.getDoctor() && _jsx(RightMarkCircleIcon, { viewBox: "0 -3 20 20" }); };
    var doctorSelection = function () {
        var _a;
        return (_jsx(Form.Item, { label: props.translate('reservationInfo.chooseDoctor'), name: "doctorName", className: "form-item body---14pt-R", rules: [
                { required: true, message: props.translate('errors.doctorName') }
            ], hasFeedback: true, initialValue: (_a = props.entity.getDoctor()) === null || _a === void 0 ? void 0 : _a.getId(), children: _jsx(Select, { showSearch: true, size: "large", placeholder: props.translate('reservationInfo.chooseDoctor'), onSelect: onSelectDoctor, filterOption: function (input, option) {
                    return !(option === null || option === void 0 ? void 0 : option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()));
                }, children: props.doctors.map(function (doctor) {
                    var _a;
                    return _jsx(Option, { className: 'body---14pt-R', value: doctor.getId(), children: (_a = doctor.getUser()) === null || _a === void 0 ? void 0 : _a.getName() }, "doctor-".concat(doctor.getId()));
                }) }) }));
    };
    var collapsedSelection = function () {
        return (_jsx("div", { className: "form-part", children: _jsx(Collapse, { defaultActiveKey: ['1'], ghost: true, expandIconPosition: "right", children: _jsx(Panel, { header: panelHeader(), extra: getExtraIcon(), children: doctorSelection() }, "1") }) }));
    };
    return (props.isCollapseRequired ? collapsedSelection() : doctorSelection());
};
export default localeTranslator(DoctorSearch, 'newReservation');
