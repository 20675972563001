import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Form, Button } from 'antd';
import EntityHeader from 'components/PageHeader/EntityHeader';
import { Package } from 'constants/resources';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import services from 'services';
var Header = function (props) {
    var navigate = useNavigate();
    var id = useParams().id;
    var onAddPackagePrice = function () { return props.setShowPackagePriceDrawer(true); };
    var permissionService = services.permissionService;
    var onEdit = function () { return navigate("/patients/".concat(id, "/update")); };
    var buttons = function () {
        return (_jsx(_Fragment, { children: permissionService.hasPermissionToAdd(Package) &&
                _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 5, children: _jsx(Form.Item, { children: _jsx(Button, { className: "normal-button Header-16-M", onClick: onAddPackagePrice, children: props.translate('header.addPackagePrice') }) }) }) }));
    };
    return (_jsx(EntityHeader, { children: buttons(), title: props.translate('header.package'), 
        // editFn={onEdit}
        resource: Package }));
};
export default localeTranslator(Header, 'showPackage');
