var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './style.css';
var ProfileHeader = function (props) {
    var getAvaterSource = function () {
        if (props.imagePath) {
            return { src: props.imagePath };
        }
        return {
            icon: _jsx(UserOutlined, {}),
            className: 'empty-user-image'
        };
    };
    return (_jsxs(Space, { className: 'profile-header-container', children: [_jsx(Avatar, __assign({}, getAvaterSource(), { size: { xs: 100, sm: 100, md: 100, lg: 100, xl: 100, xxl: 100 } })), _jsxs(Space, { direction: 'vertical', className: "text-container", children: [_jsx("div", { className: 'Header-24-bold', children: props.title }), _jsx(Space, { children: props.job && _jsx("div", { className: 'tag job-tag-color body---14pt-R ', children: props.job }) })] })] }));
};
export default ProfileHeader;
