import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Table } from 'antd';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import NormalHeader from 'components/PageHeader/NormalHeader';
var Stocks = function (props) {
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var stockService = services.stockService;
    var data = stockService.useStocks(pageNumber).data;
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('location') }),
            key: 'location',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getLocation().getName() }, 'location-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('product') }),
            key: 'product',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getProduct().getName() }, 'product-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('quantity') }),
            key: 'quantity',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getQuantity() }, 'quantity-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('pendingQuantity') }),
            key: 'pendingQuantity',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getPendingQuantity() }, 'pendingQuantity-' + index);
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header') }), _jsx("div", { className: "index-page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, 
                    // scroll={{ x: 1300 }}
                    dataSource: data === null || data === void 0 ? void 0 : data.stocks, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(Stocks, 'stocks');
