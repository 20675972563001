import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Space, Col, Button } from 'antd';
import { PackagesIcon } from 'assets/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useNavigate } from 'react-router';
import '../../style.css';
import services from 'services';
import { Package } from 'constants/resources';
var PackagePriceInfo = function (props) {
    var navigate = useNavigate();
    var permissionService = services.permissionService;
    var goToPackage = function () { return navigate("/packages/".concat(props.currentReservationPackage.getPackagePrice().getPackage().getId())); };
    return (_jsx("div", { id: "profile-content", className: "patient-info", children: _jsx(Space, { direction: 'vertical', size: 43, children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsxs(Space, { size: 27, children: [_jsx("div", { id: 'packages-icon-div', children: _jsx(PackagesIcon, {}) }), _jsxs(Space, { direction: 'vertical', children: [_jsx("div", { className: 'body---14pt-R description-text', children: props.translate('packageInfo.title') }), _jsx("div", { className: 'body---14pt-R', children: props.currentReservationPackage.getPackagePrice().getPackage().getName() })] })] }) }), _jsx(Col, { xs: 15, lg: 9, xl: 9 }), _jsx(Col, { xs: 24, lg: 5, xl: 5, children: permissionService.hasPermissionToShow(Package) &&
                            _jsx(Button, { type: "link", className: "Header-R-14", onClick: goToPackage, children: props.translate('packageInfo.viewPackage') }) })] }) }) }));
};
export default localeTranslator(PackagePriceInfo, 'showReservationPackage');
