var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { classToPlain, instanceToPlain, plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { PackagePrice, PaginationMetaData } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from './ApiService';
var PackagePriceService = /** @class */ (function (_super) {
    __extends(PackagePriceService, _super);
    function PackagePriceService() {
        var _this = _super.call(this) || this;
        _this.packagesRoot = 'packages';
        _this.packagePricesRoot = 'package_prices';
        _this.successMessage = '';
        return _this;
    }
    PackagePriceService.prototype.usePackagePrices = function () {
        var _this = this;
        return useQuery(queries.packagePrices, function () {
            return _this.get(_this.packagePricesRoot);
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(PackagePrice, data);
            }
        });
    };
    PackagePriceService.prototype.usePackagePricesToPackage = function (packageId, pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        var currentStaleTime = Object.keys(classToPlain(filters)).length ? 0 : Infinity;
        return useQuery([queries.packagePrices, pageNumber, packageId, filters], function () {
            return _this.get("".concat(_this.packagesRoot, "/").concat(packageId, "/prices"), __assign(__assign({}, classToPlain(filters)), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            retry: false,
            staleTime: currentStaleTime,
            select: function (_a) {
                var data = _a.data;
                var packagePrices = plainToClass(PackagePrice, data.package_prices);
                return {
                    packagePrices: packagePrices,
                    meta: plainToClass(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta)
                };
            }
        });
    };
    PackagePriceService.prototype.useCreatePackagePrice = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var packagePrice = _a.packagePrice, successMessage = _a.successMessage;
            _this.successMessage = successMessage;
            var currentPlainPackagePrice = classToPlain(packagePrice);
            var data = { package_price: currentPlainPackagePrice };
            return _this.post(_this.packagePricesRoot, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries(queries.packagePrices);
                _this.dipatachCreationSuccess({ successMessage: _this.successMessage });
            }
        });
    };
    PackagePriceService.prototype.useUpdatePackagePriceAvailability = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var packagePrice = _a.packagePrice, successMessage = _a.successMessage;
            _this.successMessage = successMessage;
            var currentPlainPackagePrice = instanceToPlain(packagePrice);
            var data = { package_price: currentPlainPackagePrice };
            var url = "".concat(_this.packagePricesRoot, "/").concat(packagePrice.getId(), "/update_availability");
            return _this.put(url, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries(queries.packagePrices);
                _this.dipatachCreationSuccess({ successMessage: _this.successMessage });
            }
        });
    };
    PackagePriceService.getInstance = function () {
        if (!PackagePriceService.packagePriceService) {
            PackagePriceService.packagePriceService = new PackagePriceService();
        }
        return PackagePriceService.packagePriceService;
    };
    return PackagePriceService;
}(ApiService));
export default PackagePriceService;
